.tdActions button {
    margin-left: 20px;
}

.btn-group {
    margin-left: 20px;
}

.tdSupplierName svg {
    color: #EED202;
    font-size: 150%;
    margin-bottom: 4px;
    margin-right: 4px;
}