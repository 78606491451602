@charset "UTF-8";
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #2196F3;
  --indigo: #3F51B5;
  --purple: #d066e2;
  --pink: #ff85af;
  --red: #ff6b68;
  --orange: #FF9800;
  --yellow: #FFEB3B;
  --green: #32c787;
  --teal: #39bbb0;
  --cyan: #00BCD4;
  --white: #FFFFFF;
  --gray: #868e96;
  --gray-dark: #343a40;
  --white: #FFFFFF;
  --black: #000000;
  --red: #ff6b68;
  --pink: #ff85af;
  --purple: #d066e2;
  --deep-purple: #673AB7;
  --indigo: #3F51B5;
  --blue: #2196F3;
  --light-blue: #03A9F4;
  --cyan: #00BCD4;
  --teal: #39bbb0;
  --green: #32c787;
  --light-green: #8BC34A;
  --lime: #CDDC39;
  --yellow: #FFEB3B;
  --amber: #ffc721;
  --orange: #FF9800;
  --deep-orange: #FF5722;
  --brown: #795548;
  --blue-grey: #607D8B;
  --primary: #2196F3;
  --secondary: #868e96;
  --success: #32c787;
  --info: #03A9F4;
  --warning: #ffc721;
  --danger: #ff6b68;
  --light: #f6f6f6;
  --dark: #495057;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #747a80;
  text-align: left;
  background-color: #f3f3f3; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2196F3;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0a6ebd;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 1rem 1.5rem;
  padding-bottom: 1rem 1.5rem;
  color: #9c9c9c;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #333; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e9ecef; }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f3f3f3;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

code {
  font-size: 87.5%;
  color: #ff85af;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 2px; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 500; }

pre {
  display: block;
  font-size: 87.5%;
  color: #FFFFFF; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.3333333333%;
          flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.6666666667%;
          flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333333333%;
          flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.6666666667%;
          flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.3333333333%;
          flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.6666666667%;
          flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.3333333333%;
          flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.6666666667%;
          flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 1rem 1.5rem;
    vertical-align: top;
    border-top: 1px solid #f2f4f5; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f2f4f5; }
  .table tbody + tbody {
    border-top: 2px solid #f2f4f5; }
  .table .table {
    background-color: #f3f3f3; }

.table-sm th,
.table-sm td {
  padding: 0.75rem 1rem; }

.table-bordered {
  border: 1px solid #f2f4f5; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f2f4f5; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f4f5; }

.table-hover tbody tr:hover {
  background-color: #f2f4f5; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c1e2fc; }

.table-hover .table-primary:hover {
  background-color: #a9d7fb; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a9d7fb; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2; }

.table-hover .table-secondary:hover {
  background-color: #cfd2d6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cfd2d6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c6efdd; }

.table-hover .table-success:hover {
  background-color: #b2e9d1; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b2e9d1; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e7fc; }

.table-hover .table-info:hover {
  background-color: #a0dffb; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a0dffb; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffefc1; }

.table-hover .table-warning:hover {
  background-color: #ffe8a8; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a8; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffd6d5; }

.table-hover .table-danger:hover {
  background-color: #ffbdbc; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffbdbc; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc; }

.table-hover .table-light:hover {
  background-color: #efefef; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #efefef; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #ccced0; }

.table-hover .table-dark:hover {
  background-color: #bfc1c4; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bfc1c4; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f2f4f5; }

.table-hover .table-active:hover {
  background-color: #e3e7eb; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #e3e7eb; }

.table .thead-dark th {
  color: #f3f3f3;
  background-color: #313a44;
  border-color: #3e464e; }

.table .thead-light th {
  color: #495057;
  background-color: #f2f4f5;
  border-color: #f2f4f5; }

.table-dark {
  color: #f3f3f3;
  background-color: #313a44; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #3e464e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #eceff1;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: transparent;
    border-color: #eceff1;
    outline: 0;
    box-shadow: none; }
  .form-control::-webkit-input-placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: transparent;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: transparent; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #747a80;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 2rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #9c9c9c; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #32c787; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #FFFFFF;
  background-color: rgba(50, 199, 135, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #32c787; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #32c787;
    box-shadow: 0 0 0 0.2rem rgba(50, 199, 135, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #32c787; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #32c787; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #94e4c2; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #57d59f; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f3f3f3, 0 0 0 0.2rem rgba(50, 199, 135, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #32c787; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(50, 199, 135, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff6b68; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #FFFFFF;
  background-color: rgba(255, 107, 104, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ff6b68; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #ff6b68;
    box-shadow: 0 0 0 0.2rem rgba(255, 107, 104, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff6b68; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff6b68; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #ffe8e8; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ff9d9b; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f3f3f3, 0 0 0 0.2rem rgba(255, 107, 104, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff6b68; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 107, 104, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #FFFFFF;
  background-color: #2196F3;
  border-color: #2196F3; }
  .btn-primary:hover {
    color: #FFFFFF;
    background-color: #0c83e2;
    border-color: #0c7cd5; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #2196F3;
    border-color: #2196F3; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0c7cd5;
    border-color: #0b75c9; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.5); }

.btn-secondary {
  color: #FFFFFF;
  background-color: #868e96;
  border-color: #868e96; }
  .btn-secondary:hover {
    color: #FFFFFF;
    background-color: #727b84;
    border-color: #6c757d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0 rgba(134, 142, 150, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFFFFF;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #6c757d;
    border-color: #666e76; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(134, 142, 150, 0.5); }

.btn-success {
  color: #FFFFFF;
  background-color: #32c787;
  border-color: #32c787; }
  .btn-success:hover {
    color: #FFFFFF;
    background-color: #2aa872;
    border-color: #289e6b; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0 rgba(50, 199, 135, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFFFFF;
    background-color: #32c787;
    border-color: #32c787; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #289e6b;
    border-color: #259464; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(50, 199, 135, 0.5); }

.btn-info {
  color: #FFFFFF;
  background-color: #03A9F4;
  border-color: #03A9F4; }
  .btn-info:hover {
    color: #FFFFFF;
    background-color: #038fce;
    border-color: #0286c2; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0 rgba(3, 169, 244, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFFFFF;
    background-color: #03A9F4;
    border-color: #03A9F4; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0286c2;
    border-color: #027db5; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(3, 169, 244, 0.5); }

.btn-warning {
  color: #FFFFFF;
  background-color: #ffc721;
  border-color: #ffc721; }
  .btn-warning:hover {
    color: #FFFFFF;
    background-color: #fabb00;
    border-color: #edb100; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0 rgba(255, 199, 33, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #FFFFFF;
    background-color: #ffc721;
    border-color: #ffc721; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #FFFFFF;
    background-color: #edb100;
    border-color: #e0a800; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 199, 33, 0.5); }

.btn-danger {
  color: #FFFFFF;
  background-color: #ff6b68;
  border-color: #ff6b68; }
  .btn-danger:hover {
    color: #FFFFFF;
    background-color: #ff4642;
    border-color: #ff3935; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0 rgba(255, 107, 104, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFFFFF;
    background-color: #ff6b68;
    border-color: #ff6b68; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ff3935;
    border-color: #ff2d28; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 107, 104, 0.5); }

.btn-light {
  color: #525a62;
  background-color: #f6f6f6;
  border-color: #f6f6f6; }
  .btn-light:hover {
    color: #525a62;
    background-color: #e3e3e3;
    border-color: #dddcdc; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0 rgba(246, 246, 246, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #525a62;
    background-color: #f6f6f6;
    border-color: #f6f6f6; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #525a62;
    background-color: #dddcdc;
    border-color: #d6d6d6; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(246, 246, 246, 0.5); }

.btn-dark {
  color: #FFFFFF;
  background-color: #495057;
  border-color: #495057; }
  .btn-dark:hover {
    color: #FFFFFF;
    background-color: #383d42;
    border-color: #32373b; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0 rgba(73, 80, 87, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFFFFF;
    background-color: #495057;
    border-color: #495057; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #32373b;
    border-color: #2c3034; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(73, 80, 87, 0.5); }

.btn-outline-primary {
  color: #2196F3;
  background-color: transparent;
  background-image: none;
  border-color: #2196F3; }
  .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #2196F3;
    border-color: #2196F3; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2196F3;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #2196F3;
    border-color: #2196F3; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.5); }

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96; }
  .btn-outline-secondary:hover {
    color: #FFFFFF;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(134, 142, 150, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #868e96;
    border-color: #868e96; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(134, 142, 150, 0.5); }

.btn-outline-success {
  color: #32c787;
  background-color: transparent;
  background-image: none;
  border-color: #32c787; }
  .btn-outline-success:hover {
    color: #FFFFFF;
    background-color: #32c787;
    border-color: #32c787; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(50, 199, 135, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #32c787;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #32c787;
    border-color: #32c787; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(50, 199, 135, 0.5); }

.btn-outline-info {
  color: #03A9F4;
  background-color: transparent;
  background-image: none;
  border-color: #03A9F4; }
  .btn-outline-info:hover {
    color: #FFFFFF;
    background-color: #03A9F4;
    border-color: #03A9F4; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(3, 169, 244, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #03A9F4;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #03A9F4;
    border-color: #03A9F4; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(3, 169, 244, 0.5); }

.btn-outline-warning {
  color: #ffc721;
  background-color: transparent;
  background-image: none;
  border-color: #ffc721; }
  .btn-outline-warning:hover {
    color: #FFFFFF;
    background-color: #ffc721;
    border-color: #ffc721; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(255, 199, 33, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc721;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ffc721;
    border-color: #ffc721; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 199, 33, 0.5); }

.btn-outline-danger {
  color: #ff6b68;
  background-color: transparent;
  background-image: none;
  border-color: #ff6b68; }
  .btn-outline-danger:hover {
    color: #FFFFFF;
    background-color: #ff6b68;
    border-color: #ff6b68; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(255, 107, 104, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ff6b68;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ff6b68;
    border-color: #ff6b68; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 107, 104, 0.5); }

.btn-outline-light {
  color: #f6f6f6;
  background-color: transparent;
  background-image: none;
  border-color: #f6f6f6; }
  .btn-outline-light:hover {
    color: #525a62;
    background-color: #f6f6f6;
    border-color: #f6f6f6; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(246, 246, 246, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f6f6f6;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #525a62;
    background-color: #f6f6f6;
    border-color: #f6f6f6; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(246, 246, 246, 0.5); }

.btn-outline-dark {
  color: #495057;
  background-color: transparent;
  background-image: none;
  border-color: #495057; }
  .btn-outline-dark:hover {
    color: #FFFFFF;
    background-color: #495057;
    border-color: #495057; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(73, 80, 87, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #495057;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #495057;
    border-color: #495057; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(73, 80, 87, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2196F3;
  background-color: transparent; }
  .btn-link:hover {
    color: #0a6ebd;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #868e96;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 2px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.8rem 0;
  margin: 0 0 0;
  font-size: 1rem;
  color: #747a80;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 2px; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f6f6f6; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #6d6d6d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f9f9f9; }
  .dropdown-item.active, .dropdown-item:active {
    color: #6d6d6d;
    text-decoration: none;
    background-color: #f6f6f6; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.8rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #adb5bd;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1.5rem;
  color: #6d6d6d; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -2px; }

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -2px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #FFFFFF;
  border: 1px solid #eceff1;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #2196F3; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #f3f3f3, 0 0 0 0.2rem rgba(33, 150, 243, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #cae6fc; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #868e96; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 2px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2196F3; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #2196F3; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23FFFFFF' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(33, 150, 243, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(33, 150, 243, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2196F3; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFFFFF'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(33, 150, 243, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #eceff1;
  border-radius: 2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #eceff1;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(236, 239, 241, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: transparent; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #868e96;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #eceff1;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #eceff1; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  border: 1px solid #eceff1;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #FFFFFF;
    border-left: 1px solid #eceff1;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2196F3;
    border: 0;
    border-radius: 1rem;
    -webkit-appearance: none;
            appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #f3f3f3, 0 0 0 0.2rem rgba(33, 150, 243, 0.25); }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #cae6fc; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2196F3;
    border: 0;
    border-radius: 1rem;
    -moz-appearance: none;
         appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #f3f3f3, 0 0 0 0.2rem rgba(33, 150, 243, 0.25); }
    .custom-range::-moz-range-thumb:active {
      background-color: #cae6fc; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2196F3;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #f3f3f3, 0 0 0 0.2rem rgba(33, 150, 243, 0.25); }
    .custom-range::-ms-thumb:active {
      background-color: #cae6fc; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 1rem 1.2rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868e96; }

.nav-tabs {
  border-bottom: 2px solid #f6f6f6; }
  .nav-tabs .nav-item {
    margin-bottom: -2px; }
  .nav-tabs .nav-link {
    border: 2px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #f6f6f6; }
    .nav-tabs .nav-link.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: inherit;
    background-color: transparent;
    border-color: #dee2e6 #dee2e6 transparent; }
  .nav-tabs .dropdown-menu {
    margin-top: -2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 2px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #2196F3; }

.nav-fill .nav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFFFFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFFFFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFFFFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFFFFF; }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid transparent;
  border-radius: 2px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }

.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 2.2rem; }

.card-title {
  margin-bottom: 2.1rem; }

.card-subtitle {
  margin-top: -1.05rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 2.2rem; }

.card-header {
  padding: 2.1rem 2.2rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid transparent; }
  .card-header:first-child {
    border-radius: 2px 2px 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 2.1rem 2.2rem;
  background-color: transparent;
  border-top: 1px solid transparent; }
  .card-footer:last-child {
    border-radius: 0 0 2px 2px; }

.card-header-tabs {
  margin-right: -1.1rem;
  margin-bottom: -2.1rem;
  margin-left: -1.1rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -1.1rem;
  margin-left: -1.1rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0; }

.card-img {
  width: 100%;
  border-radius: 2px; }

.card-img-top {
  width: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 0%;
                flex: 1 0 0%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap; }
      .card-group > .card {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 0%;
                flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 2px; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 2px; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 2.3rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem 0.25rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 2px; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #868e96;
    content: ""; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #868e96; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px; }

.page-link {
  position: relative;
  display: block;
  padding: 0 0;
  margin-left: 0;
  line-height: 1.25;
  color: #8e9499;
  background-color: #f3f3f3;
  border: 0 solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #81878d;
    text-decoration: none;
    background-color: #e6e6e6;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFFFFF;
  background-color: #03A9F4;
  border-color: #03A9F4; }

.page-item.disabled .page-link {
  color: #8e9499;
  pointer-events: none;
  cursor: auto;
  background-color: #f3f3f3;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.badge {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 90%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 10rem; }

.badge-primary {
  color: #FFFFFF;
  background-color: #2196F3; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #0c7cd5; }

.badge-secondary {
  color: #FFFFFF;
  background-color: #868e96; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #6c757d; }

.badge-success {
  color: #FFFFFF;
  background-color: #32c787; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #289e6b; }

.badge-info {
  color: #FFFFFF;
  background-color: #03A9F4; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #0286c2; }

.badge-warning {
  color: #FFFFFF;
  background-color: #ffc721; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #edb100; }

.badge-danger {
  color: #FFFFFF;
  background-color: #ff6b68; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #ff3935; }

.badge-light {
  color: #525a62;
  background-color: #f6f6f6; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #525a62;
    text-decoration: none;
    background-color: #dddcdc; }

.badge-dark {
  color: #FFFFFF;
  background-color: #495057; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #32373b; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #FFFFFF;
  border-radius: 2px; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 1.1rem 1.5rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 2px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: normal; }

.alert-dismissible {
  padding-right: 4.5rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.1rem 1.5rem;
    color: inherit; }

.alert-primary {
  color: white;
  background-color: #2196f3;
  border-color: #c1e2fc; }
  .alert-primary hr {
    border-top-color: #a9d7fb; }
  .alert-primary .alert-link {
    color: #e6e5e5; }

.alert-secondary {
  color: white;
  background-color: #868e96;
  border-color: #dddfe2; }
  .alert-secondary hr {
    border-top-color: #cfd2d6; }
  .alert-secondary .alert-link {
    color: #e6e5e5; }

.alert-success {
  color: white;
  background-color: #32c787;
  border-color: #c6efdd; }
  .alert-success hr {
    border-top-color: #b2e9d1; }
  .alert-success .alert-link {
    color: #e6e5e5; }

.alert-info {
  color: white;
  background-color: #03a9f4;
  border-color: #b8e7fc; }
  .alert-info hr {
    border-top-color: #a0dffb; }
  .alert-info .alert-link {
    color: #e6e5e5; }

.alert-warning {
  color: white;
  background-color: #ffc721;
  border-color: #ffefc1; }
  .alert-warning hr {
    border-top-color: #ffe8a8; }
  .alert-warning .alert-link {
    color: #e6e5e5; }

.alert-danger {
  color: white;
  background-color: #ff6b68;
  border-color: #ffd6d5; }
  .alert-danger hr {
    border-top-color: #ffbdbc; }
  .alert-danger .alert-link {
    color: #e6e5e5; }

.alert-light {
  color: white;
  background-color: #f6f6f6;
  border-color: #fcfcfc; }
  .alert-light hr {
    border-top-color: #efefef; }
  .alert-light .alert-link {
    color: #e6e5e5; }

.alert-dark {
  color: white;
  background-color: #495057;
  border-color: #ccced0; }
  .alert-dark hr {
    border-top-color: #bfc1c4; }
  .alert-dark .alert-link {
    color: #e6e5e5; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 5px 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 5px 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 5px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 2px; }

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #2196F3;
  text-align: center;
  white-space: nowrap;
  background-color: #2196F3;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 5px 5px; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: black; }
  .list-group-item-action:active {
    color: #747a80;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 2rem;
  margin-bottom: 0;
  background-color: transparent;
  border: 0 solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868e96;
    background-color: transparent; }
  .list-group-item.active {
    z-index: 2;
    color: #FFFFFF;
    background-color: #2196F3;
    border-color: #2196F3; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #114e7e;
  background-color: #c1e2fc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #114e7e;
    background-color: #a9d7fb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #114e7e;
    border-color: #114e7e; }

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #464a4e;
    background-color: #cfd2d6; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #464a4e;
    border-color: #464a4e; }

.list-group-item-success {
  color: #1a6746;
  background-color: #c6efdd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1a6746;
    background-color: #b2e9d1; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #1a6746;
    border-color: #1a6746; }

.list-group-item-info {
  color: #02587f;
  background-color: #b8e7fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #02587f;
    background-color: #a0dffb; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #02587f;
    border-color: #02587f; }

.list-group-item-warning {
  color: #856711;
  background-color: #ffefc1; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856711;
    background-color: #ffe8a8; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #856711;
    border-color: #856711; }

.list-group-item-danger {
  color: #853836;
  background-color: #ffd6d5; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #853836;
    background-color: #ffbdbc; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #853836;
    border-color: #853836; }

.list-group-item-light {
  color: gray;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: gray;
    background-color: #efefef; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: gray;
    border-color: gray; }

.list-group-item-dark {
  color: #262a2d;
  background-color: #ccced0; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #262a2d;
    background-color: #bfc1c4; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #262a2d;
    border-color: #262a2d; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1;
  color: #000000;
  text-shadow: none;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.2; }

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 25px 30px 0;
  border-bottom: 0 solid #e9ecef;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }
  .modal-header .close {
    padding: 25px 30px 0;
    margin: -25px 30px 0 -25px 30px 0 -25px 30px 0 auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 25px 30px; }

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 25px 30px;
  border-top: 0 solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 1000px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #495057; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #495057; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #495057; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #495057; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.7rem 1.1rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #495057;
  border-radius: 2px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 2px; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 2px; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.05); }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #FFFFFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 2px 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.05); }
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #FFFFFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.05); }
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #FFFFFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #FFFFFF; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 2px 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.05); }
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #FFFFFF; }

.popover-header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #333;
  background-color: #FFFFFF;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: calc(2px - 1px);
  border-top-right-radius: calc(2px - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1.25rem 1.5rem;
  color: #747a80; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.8; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFFFFF;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: none; }

.carousel-control-next-icon {
  background-image: none; }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #FFFFFF; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #2196F3 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0c7cd5 !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #32c787 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #289e6b !important; }

.bg-info {
  background-color: #03A9F4 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0286c2 !important; }

.bg-warning {
  background-color: #ffc721 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #edb100 !important; }

.bg-danger {
  background-color: #ff6b68 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff3935 !important; }

.bg-light {
  background-color: #f6f6f6 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dddcdc !important; }

.bg-dark {
  background-color: #495057 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #32373b !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2196F3 !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #32c787 !important; }

.border-info {
  border-color: #03A9F4 !important; }

.border-warning {
  border-color: #ffc721 !important; }

.border-danger {
  border-color: #ff6b68 !important; }

.border-light {
  border-color: #f6f6f6 !important; }

.border-dark {
  border-color: #495057 !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded {
  border-radius: 2px !important; }

.rounded-top {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important; }

.rounded-right {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important; }

.rounded-bottom {
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-left {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 500 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #2196F3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0c7cd5 !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #6c757d !important; }

.text-success {
  color: #32c787 !important; }

a.text-success:hover, a.text-success:focus {
  color: #289e6b !important; }

.text-info {
  color: #03A9F4 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0286c2 !important; }

.text-warning {
  color: #ffc721 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #edb100 !important; }

.text-danger {
  color: #ff6b68 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ff3935 !important; }

.text-light {
  color: #f6f6f6 !important; }

a.text-light:hover, a.text-light:focus {
  color: #dddcdc !important; }

.text-dark {
  color: #495057 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #32373b !important; }

.text-body {
  color: #747a80 !important; }

.text-muted {
  color: #9c9c9c !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #f2f4f5; }
  .table .thead-dark th {
    color: inherit;
    border-color: #f2f4f5; } }

.dropdown,
.dropup {
  position: relative; }

.dropdown-item {
  line-height: 1.5;
  transition: background-color 300ms, color 300ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    .dropdown-item {
      transition: none; } }

.dropdown-menu {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11); }

.dropdown-header {
  font-size: 1rem;
  font-weight: normal; }

.dropdown-menu--block {
  width: 320px; }
  @media (max-width: 575.98px) {
    .dropdown-menu--block {
      width: 100%; } }

.dropdown-menu--icon .dropdown-item > i {
  line-height: 100%;
  vertical-align: top;
  font-size: 1.4rem;
  width: 2rem; }

.dropdown-menu--sort > .checkbox {
  white-space: nowrap;
  padding: 0.5rem 1.5rem 0.25rem; }

.card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  margin-bottom: 2.3rem; }
  .card:not([class*="border-"]) {
    border: 0; }

.card--inverse, .card--inverse .card-title {
  color: #FFFFFF; }

.card--inverse .card-subtitle {
  color: rgba(255, 255, 255, 0.75); }

.card-title {
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 140%;
  margin-top: -0.35rem; }
  .card-title:last-child {
    margin-bottom: 0; }

.card-subtitle {
  color: #9c9c9c;
  font-size: 1rem;
  font-weight: normal;
  margin-top: -1.5rem;
  line-height: 1.5; }
  .card-subtitle:not(:last-child) {
    margin-bottom: 2rem; }

.card > .actions,
.card-body > .actions {
  position: absolute;
  right: 15px;
  z-index: 2;
  top: 18px; }

[class*="card-img"] {
  width: 100%; }

.card-link {
  text-transform: uppercase;
  font-size: 0.98rem;
  color: #333;
  display: inline-block;
  margin-top: 1rem;
  transition: color 300ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    .card-link {
      transition: none; } }
  .card-link:hover {
    color: #666666; }

.card-body .card-body {
  margin-bottom: 0; }

.card-body + .listview {
  margin-top: -0.9rem; }

.card-body__title {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 1rem;
  font-weight: normal; }

.card--fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 19;
  padding-top: 72px;
  overflow: auto; }

.card-header,
.card-footer {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  background-color: #f9f9f9; }

.card-header {
  margin-bottom: -0.35rem; }

.card-footer {
  margin-top: -0.35rem; }

.btn {
  transition: box-shadow 300ms, background-color 300ms, border-color 300ms; }
  .btn:not([class*="btn-outline-"]) {
    border-color: transparent !important; }

.btn--raised {
  box-shadow: 0 4px 3px -2px rgba(0, 0, 0, 0.15), 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 1px 5px 0 rgba(0, 0, 0, 0.02) !important; }
  .btn--raised:hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.15), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important; }
  .btn--raised:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 10px 2px rgba(0, 0, 0, 0.12) !important; }

.btn--icon {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  padding: 0;
  font-size: 1.2rem;
  text-align: center; }

.btn--icon-text > .zmdi {
  font-size: 1.15rem;
  margin: -1px 5px 0 0;
  vertical-align: middle; }

.btn--action {
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 4px 3px -2px rgba(0, 0, 0, 0.15), 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 1px 5px 0 rgba(0, 0, 0, 0.02) !important;
  position: fixed;
  bottom: 30px;
  right: 30px; }
  .btn--action:hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.15), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important; }
  .btn--action:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 10px 2px rgba(0, 0, 0, 0.12) !important; }
  .btn--action, .btn--action:hover, .btn--action:focus {
    color: #FFFFFF; }

.btn-group-justified {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }
  .btn-group-justified .btn,
  .btn-group-justified .btn-group {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
    .btn-group-justified .btn .btn,
    .btn-group-justified .btn-group .btn {
      width: 100%; }

[data-toggle="buttons"]:not(.btn-group--colors) > .btn {
  background-color: #f6f6f6;
  cursor: pointer;
  box-shadow: none;
  border: 0;
  margin: 0; }
  [data-toggle="buttons"]:not(.btn-group--colors) > .btn:not(.active) {
    color: #747a80; }
  [data-toggle="buttons"]:not(.btn-group--colors) > .btn.active {
    background-color: #03A9F4;
    color: #FFFFFF; }

.btn-group--colors > .btn {
  box-shadow: none;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-bottom: 3px;
  position: relative; }
  .btn-group--colors > .btn:not([class*="bg-"]) {
    border-color: #f6f6f6 !important; }
  .btn-group--colors > .btn:before {
    font-family: "Material-Design-Iconic-Font";
    content: "";
    font-size: 16px;
    transition: opacity 200ms, -webkit-transform 200ms;
    transition: transform 200ms, opacity 200ms;
    transition: transform 200ms, opacity 200ms, -webkit-transform 200ms;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 28px;
    padding-right: 3px;
    color: #FFFFFF;
    font-style: italic;
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
    @media screen and (prefers-reduced-motion: reduce) {
      .btn-group--colors > .btn:before {
        transition: none; } }
  .btn-group--colors > .btn.btn:not([class*="bg-"]) {
    border: 1px solid #dde2e6; }
    .btn-group--colors > .btn.btn:not([class*="bg-"]):before {
      color: #747a80; }
  .btn-group--colors > .btn.active:before {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }

.table thead th {
  border-bottom-width: 1px; }

.table:not(.table-dark) thead:not(.thead-dark) {
  color: #333; }

.table tr[class*="table-"] td,
.table tr[class*="table-"] th,
.table tr[class*="table-"] + tr td,
.table tr[class*="table-"] + tr th {
  border: 0; }

.table th {
  font-weight: 500; }

.table:not(.table-bordered) > thead:first-child th, .table:not(.table-bordered) > thead:first-child td,
.table:not(.table-bordered) > tbody:first-child th,
.table:not(.table-bordered) > tbody:first-child td {
  border-top: 0; }

.data-table table th {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  position: relative; }
  .data-table table th > i.fa {
    position: absolute;
    font-style: normal;
    right: 0.3rem;
    bottom: 0.6rem;
    font-size: 1.4rem; }
    .data-table table th > i.fa:before {
      font-family: "Material-Design-Iconic-Font"; }
    .data-table table th > i.fa.fa-chevron-up:before {
      content: '\f1ce'; }
    .data-table table th > i.fa.fa-chevron-down:before {
      content: '\f1cd'; }

.data-table tr > td:first-child,
.data-table tr > th:first-child {
  padding-left: 2.2rem; }

.data-table tr > td:last-child,
.data-table tr > th:last-child {
  padding-right: 2.2rem; }

.data-table__header {
  padding: 0 2.2rem 2rem; }

.data-table__filter {
  max-width: 500px; }
  .data-table__filter .form-control {
    padding-left: 2rem; }
  .data-table__filter:before {
    content: '\f1c3';
    font-family: "Material-Design-Iconic-Font";
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    bottom: 0.263rem; }

.data-table__footer {
  text-align: center;
  padding: 2.1rem 2.2rem; }

.form-control {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  resize: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -ms-overflow-style: none; }
  .form-control:not(:disabled):not([readonly]):focus ~ .form-group__bar:before, .form-control:not(:disabled):not([readonly]):focus ~ .form-group__bar:after {
    width: 50%; }
  .form-control:disabled, .form-control[readonly] {
    opacity: 0.6; }
  .form-control.is-valid:focus, .form-control.is-invalid:focus {
    box-shadow: none; }

.form-group {
  position: relative; }

.form-group__bar {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 100%; }
  .form-group__bar:before, .form-group__bar:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    bottom: 0;
    transition: all 350ms;
    transition-timing-function: ease;
    background-color: #03A9F4; }
    @media screen and (prefers-reduced-motion: reduce) {
      .form-group__bar:before, .form-group__bar:after {
        transition: none; } }
  .form-group__bar:before {
    left: 50%; }
  .form-group__bar:after {
    right: 50%; }

select::-ms-expand {
  display: none; }

.select:before {
  content: "";
  position: absolute;
  pointer-events: none;
  z-index: 1;
  right: 0;
  bottom: 5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 8px 8px;
  border-color: transparent transparent #d1d1d1 transparent; }

select.form-control {
  padding-bottom: 0;
  padding-top: 0; }

.form-group--float {
  margin-top: 2.5rem; }
  .form-group--float .form-control:focus ~ label,
  .form-group--float .form-control.form-control--active ~ label {
    bottom: 2.25rem;
    font-size: 0.875rem; }
  .form-group--float .form-control ~ label {
    font-size: 1rem;
    bottom: 0.375rem;
    width: 100%; }
  .form-group--float .form-control:focus ~ label {
    color: #03A9F4; }
  .form-group--float .form-control-sm:focus ~ label, .form-group--float .input-group-sm > .form-control:focus ~ label, .form-group--float
  .input-group-sm > .input-group-prepend > .input-group-text:focus ~ label, .form-group--float
  .input-group-sm > .input-group-append > .input-group-text:focus ~ label, .form-group--float
  .input-group-sm > .input-group-prepend > .btn:focus ~ label, .form-group--float
  .input-group-sm > .input-group-append > .btn:focus ~ label,
  .form-group--float .form-control-sm.form-control--active ~ label,
  .form-group--float .input-group-sm > .form-control--active.form-control ~ label,
  .form-group--float
  .input-group-sm > .input-group-prepend > .form-control--active.input-group-text ~ label,
  .form-group--float
  .input-group-sm > .input-group-append > .form-control--active.input-group-text ~ label,
  .form-group--float
  .input-group-sm > .input-group-prepend > .form-control--active.btn ~ label,
  .form-group--float
  .input-group-sm > .input-group-append > .form-control--active.btn ~ label {
    bottom: 1.75rem;
    font-size: 0.775rem; }
  .form-group--float .form-control-sm ~ label, .form-group--float .input-group-sm > .form-control ~ label, .form-group--float
  .input-group-sm > .input-group-prepend > .input-group-text ~ label, .form-group--float
  .input-group-sm > .input-group-append > .input-group-text ~ label, .form-group--float
  .input-group-sm > .input-group-prepend > .btn ~ label, .form-group--float
  .input-group-sm > .input-group-append > .btn ~ label {
    font-size: 0.875rem;
    bottom: 0.5rem; }
  .form-group--float .form-control-lg:focus ~ label, .form-group--float .input-group-lg > .form-control:focus ~ label, .form-group--float
  .input-group-lg > .input-group-prepend > .input-group-text:focus ~ label, .form-group--float
  .input-group-lg > .input-group-append > .input-group-text:focus ~ label, .form-group--float
  .input-group-lg > .input-group-prepend > .btn:focus ~ label, .form-group--float
  .input-group-lg > .input-group-append > .btn:focus ~ label,
  .form-group--float .form-control-lg.form-control--active ~ label,
  .form-group--float .input-group-lg > .form-control--active.form-control ~ label,
  .form-group--float
  .input-group-lg > .input-group-prepend > .form-control--active.input-group-text ~ label,
  .form-group--float
  .input-group-lg > .input-group-append > .form-control--active.input-group-text ~ label,
  .form-group--float
  .input-group-lg > .input-group-prepend > .form-control--active.btn ~ label,
  .form-group--float
  .input-group-lg > .input-group-append > .form-control--active.btn ~ label {
    bottom: 2.65rem;
    font-size: 1rem; }
  .form-group--float .form-control-lg ~ label, .form-group--float .input-group-lg > .form-control ~ label, .form-group--float
  .input-group-lg > .input-group-prepend > .input-group-text ~ label, .form-group--float
  .input-group-lg > .input-group-append > .input-group-text ~ label, .form-group--float
  .input-group-lg > .input-group-prepend > .btn ~ label, .form-group--float
  .input-group-lg > .input-group-append > .btn ~ label {
    font-size: 1.25rem;
    bottom: 0.5rem; }
  .form-group--float > label {
    color: #868e96;
    pointer-events: none;
    left: 0;
    position: absolute;
    margin: 0;
    transition: bottom 200ms ease-out, color 200ms ease-out, font-size 200ms ease-out, color 300ms; }

.form-group--centered,
.form-group--centered .form-control {
  text-align: center; }

.invalid-feedback,
.valid-feedback {
  position: absolute;
  left: 0;
  bottom: -1.5rem; }

.is-valid ~ .form-group__bar:before, .is-valid ~ .form-group__bar:after {
  background-color: #32c787; }

.is-valid ~ .form-group__feedback {
  color: #32c787; }

.is-invalid ~ .form-group__bar:before, .is-invalid ~ .form-group__bar:after {
  background-color: #ff6b68; }

.is-invalid ~ .form-group__feedback {
  color: #ff6b68; }

.valid-tooltip,
.invalid-tooltip {
  margin-top: 0;
  border-radius: 0;
  padding: 0.25rem 0.5rem 0.35rem; }

.form-group__feedback {
  font-size: 1.25rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0; }

.icon-toggle {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .icon-toggle .zmdi {
    z-index: 2;
    font-size: 1.5rem;
    color: #ced4da;
    transition: color 300ms; }
  .icon-toggle input[type="checkbox"] {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0; }
    .icon-toggle input[type="checkbox"]:checked ~ .zmdi {
      color: #39bbb0; }
  .icon-toggle:hover .zmdi {
    color: #adb5bd; }

.icon-toggle--red input[type="checkbox"]:checked ~ .zmdi {
  color: #ff6b68; }

.icon-toggle--blue input[type="checkbox"]:checked ~ .zmdi {
  color: #2196F3; }

.icon-toggle--green input[type="checkbox"]:checked ~ .zmdi {
  color: #32c787; }

.icon-toggle--amber input[type="checkbox"]:checked ~ .zmdi {
  color: #ffc721; }

.icon-toggle--blue-grey input[type="checkbox"]:checked ~ .zmdi {
  color: #607D8B; }

.form-text {
  margin-top: -1.5rem; }

.input-group {
  margin-bottom: 2rem; }
  .input-group .form-control {
    padding-right: 1rem;
    padding-left: 1rem; }

.input-group-text {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  line-height: 100%; }
  .input-group-text > .zmdi {
    position: relative;
    top: 1px; }

.checkbox,
.radio {
  position: relative;
  line-height: 1.5rem; }
  .checkbox + .checkbox,
  .checkbox + .radio,
  .radio + .checkbox,
  .radio + .radio {
    margin-top: 0.5rem; }
  .checkbox:not(.checkbox--inline):not(
  .radio--inline):not(.checkbox--inline):not(
  .radio--inline),
  .radio:not(.checkbox--inline):not(
  .radio--inline):not(.checkbox--inline):not(
  .radio--inline) {
    display: block; }
  .checkbox--inline,
  .radio--inline {
    display: inline-block; }
    .checkbox--inline:not(:last-child),
    .radio--inline:not(:last-child) {
      margin-right: 2rem; }
  .checkbox > input[type=checkbox],
  .checkbox > input[type=radio],
  .radio > input[type=checkbox],
  .radio > input[type=radio] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none; }
    .checkbox > input[type=checkbox]:checked ~ .checkbox__label:before,
    .checkbox > input[type=radio]:checked ~ .checkbox__label:before,
    .radio > input[type=checkbox]:checked ~ .checkbox__label:before,
    .radio > input[type=radio]:checked ~ .checkbox__label:before {
      background-color: #39bbb0; }
    .checkbox > input[type=checkbox]:checked ~ .checkbox__label:before,
    .checkbox > input[type=checkbox]:checked ~ .radio__label:before,
    .checkbox > input[type=radio]:checked ~ .checkbox__label:before,
    .checkbox > input[type=radio]:checked ~ .radio__label:before,
    .radio > input[type=checkbox]:checked ~ .checkbox__label:before,
    .radio > input[type=checkbox]:checked ~ .radio__label:before,
    .radio > input[type=radio]:checked ~ .checkbox__label:before,
    .radio > input[type=radio]:checked ~ .radio__label:before {
      border-color: #39bbb0; }
    .checkbox > input[type=checkbox]:checked ~ .checkbox__label:after,
    .checkbox > input[type=checkbox]:checked ~ .radio__label:after,
    .checkbox > input[type=radio]:checked ~ .checkbox__label:after,
    .checkbox > input[type=radio]:checked ~ .radio__label:after,
    .radio > input[type=checkbox]:checked ~ .checkbox__label:after,
    .radio > input[type=checkbox]:checked ~ .radio__label:after,
    .radio > input[type=radio]:checked ~ .checkbox__label:after,
    .radio > input[type=radio]:checked ~ .radio__label:after {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1; }
    .checkbox > input[type=checkbox]:disabled ~ .checkbox__label,
    .checkbox > input[type=checkbox]:disabled ~ .radio__label,
    .checkbox > input[type=radio]:disabled ~ .checkbox__label,
    .checkbox > input[type=radio]:disabled ~ .radio__label,
    .radio > input[type=checkbox]:disabled ~ .checkbox__label,
    .radio > input[type=checkbox]:disabled ~ .radio__label,
    .radio > input[type=radio]:disabled ~ .checkbox__label,
    .radio > input[type=radio]:disabled ~ .radio__label {
      opacity: 0.5;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }

.checkbox__label,
.radio__label {
  position: relative;
  min-width: 18px;
  min-height: 18px;
  padding-left: 27px;
  text-align: left;
  margin: 0; }
  .checkbox__label:before, .checkbox__label:after,
  .radio__label:before,
  .radio__label:after {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 0; }
  .checkbox__label:before,
  .radio__label:before {
    content: '';
    border: 2px solid "#6e6e6e";
    background-color: transparent;
    transition: border-color 200ms, background-color 200ms; }
  .checkbox__label:after,
  .radio__label:after {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: opacity 150ms, -webkit-transform 150ms;
    transition: transform 150ms, opacity 150ms;
    transition: transform 150ms, opacity 150ms, -webkit-transform 150ms; }

.checkbox__label:before {
  border-radius: 2px; }

.checkbox__label:after {
  font-family: "Material-Design-Iconic-Font";
  content: "";
  font-size: 1.25rem;
  text-align: center;
  color: #FFFFFF;
  line-height: 18px; }

.radio__label:before {
  border-radius: 50%; }

.radio__label:after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #39bbb0;
  top: 5px;
  left: 5px; }

.checkbox--char > input[type=checkbox]:checked ~ .checkbox__char {
  font-size: 0;
  background-color: #adb5bd !important; }
  .checkbox--char > input[type=checkbox]:checked ~ .checkbox__char:after {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    opacity: 1; }

.checkbox--char > input[type=checkbox]:not(:checked) ~ .checkbox__char:hover {
  font-size: 0; }
  .checkbox--char > input[type=checkbox]:not(:checked) ~ .checkbox__char:hover:before {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    opacity: 1; }

.checkbox__char {
  border-radius: 50%;
  position: relative;
  height: 40px;
  width: 40px;
  color: #FFFFFF;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  font-style: normal;
  transition: font-size 200ms ease, background-color 300ms; }
  .checkbox__char:before, .checkbox__char:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    opacity: 0;
    transition: opacity 300ms ease, -webkit-transform 300ms ease;
    transition: transform 300ms ease, opacity 300ms ease;
    transition: transform 300ms ease, opacity 300ms ease, -webkit-transform 300ms ease;
    font-weight: normal; }
  .checkbox__char:before {
    font-family: "Material-Design-Iconic-Font";
    content: "";
    font-size: 1.35rem; }
  .checkbox__char:after {
    font-family: "Material-Design-Iconic-Font";
    content: "";
    font-size: 1.5rem;
    -webkit-transform: scale3d(0, 0, 0);
            transform: scale3d(0, 0, 0); }

.page-item.disabled {
  opacity: 0.6; }

.page-link {
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  margin: 0 1px; }
  .page-link > .zmdi {
    font-size: 1.5rem; }
  .page-link:focus {
    box-shadow: none; }

.pagination-next .page-link,
.pagination-prev .page-link,
.pagination-first .page-link,
.pagination-last .page-link {
  font-size: 0; }
  .pagination-next .page-link:before,
  .pagination-prev .page-link:before,
  .pagination-first .page-link:before,
  .pagination-last .page-link:before {
    font-family: "Material-Design-Iconic-Font";
    font-size: 1rem; }

.pagination-prev .page-link:before {
  content: '\f2ff'; }

.pagination-next .page-link:before {
  content: '\f301'; }

.pagination-first .page-link:before,
.pagination-last .page-link:before {
  content: '\f302'; }

.pagination-first .page-link:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  display: inline-block; }

.alert-success .alert-link,
.alert-danger .alert-link,
.alert-info .alert-link,
.alert-warning .alert-link {
  color: #FFFFFF; }

.alert-link {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25); }

.alert-dismissible .close {
  opacity: 0.5; }
  .alert-dismissible .close:hover {
    color: #FFFFFF;
    opacity: 1; }

.alert-heading {
  font-size: 1.1rem; }

.alert--notify {
  max-width: 600px;
  width: calc(100% - 30px);
  padding-right: 80px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.85); }
  .alert--notify:not(.alert-info):not(.alert-success):not(.alert-warning):not(.alert-danger) {
    background-color: rgba(0, 0, 0, 0.95); }
    .alert--notify:not(.alert-info):not(.alert-success):not(.alert-warning):not(.alert-danger) .alert--notify__close {
      color: #FFEB3B; }
      .alert--notify:not(.alert-info):not(.alert-success):not(.alert-warning):not(.alert-danger) .alert--notify__close:hover {
        opacity: 0.8; }

.alert--notify__close {
  background-color: transparent;
  border: 0;
  text-transform: uppercase;
  padding: 0;
  cursor: pointer;
  font-weight: 500;
  position: absolute;
  right: 1.5rem;
  top: 1.1rem;
  color: #FFFFFF; }

.close {
  transition: opacity 300ms;
  cursor: pointer; }
  @media screen and (prefers-reduced-motion: reduce) {
    .close {
      transition: none; } }
  .close, .close:hover {
    opacity: 1; }

.breadcrumb {
  border-bottom: 1px solid #e9ecef;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item:before {
  font-family: "Material-Design-Iconic-Font";
  position: relative;
  top: 1px;
  color: #9c9c9c; }

.breadcrumb-item > a,
a.breadcrumb-item {
  color: #747a80; }
  .breadcrumb-item > a:hover,
  a.breadcrumb-item:hover {
    color: #5c6165; }

.accordion__item {
  border: 1px solid #eee; }
  .accordion__item + .accordion__item {
    margin-top: -1px; }

.accordion__title {
  display: block;
  cursor: pointer;
  padding: 0.85rem 1.35rem;
  color: #333;
  transition: background-color 500ms; }
  .accordion__title:hover {
    background-color: #f9f9f9; }

.accordion__content {
  padding: 0.85rem 1.35rem; }

.carousel-item img {
  width: 100%;
  border-radius: 2px; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  position: relative; }
  .carousel-control-prev-icon:before, .carousel-control-prev-icon:after,
  .carousel-control-next-icon:before,
  .carousel-control-next-icon:after {
    content: '';
    font-family: "Material-Design-Iconic-Font";
    font-size: 2rem;
    color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    transition: opacity 250ms linear, -webkit-transform 250ms linear;
    transition: opacity 250ms linear, transform 250ms linear;
    transition: opacity 250ms linear, transform 250ms linear, -webkit-transform 250ms linear; }
    @media screen and (prefers-reduced-motion: reduce) {
      .carousel-control-prev-icon:before, .carousel-control-prev-icon:after,
      .carousel-control-next-icon:before,
      .carousel-control-next-icon:after {
        transition: none; } }
  .carousel-control-prev-icon:after,
  .carousel-control-next-icon:after {
    -webkit-transform: scale(5);
            transform: scale(5);
    opacity: 0; }

.carousel-control-prev:hover .carousel-control-prev-icon:after,
.carousel-control-prev:hover .carousel-control-next-icon:after,
.carousel-control-next:hover .carousel-control-prev-icon:after,
.carousel-control-next:hover .carousel-control-next-icon:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.carousel-control-prev:hover .carousel-control-prev-icon:before,
.carousel-control-prev:hover .carousel-control-next-icon:before,
.carousel-control-next:hover .carousel-control-prev-icon:before,
.carousel-control-next:hover .carousel-control-next-icon:before {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0; }

.carousel-control-prev-icon:before, .carousel-control-prev-icon:after {
  content: '\f2ff'; }

.carousel-control-next-icon:before, .carousel-control-next-icon:after {
  content: '\f301'; }

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2px 2px 0 0;
  bottom: 0;
  font-weight: 500;
  padding-bottom: 35px; }
  .carousel-caption h3 {
    color: #FFFFFF;
    font-size: 1.3rem; }

.modal-content {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07); }

.modal-title {
  font-size: 1.2rem;
  font-weight: normal; }

.modal-footer {
  padding-top: 0.8rem; }
  .modal-footer > .btn-link {
    color: #333;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 2px; }
    .modal-footer > .btn-link:hover, .modal-footer > .btn-link:focus {
      background-color: #f6f6f6; }

.popover {
  font-size: 1rem;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1); }

.popover-header {
  border: 0;
  margin-bottom: -2rem; }

.nav-tabs .nav-link {
  border: 0;
  text-transform: uppercase;
  color: #9c9c9c;
  position: relative;
  font-size: 0.95rem;
  transition: color 400ms; }
  .nav-tabs .nav-link:before {
    content: "";
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #03A9F4;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 400ms;
    transition: transform 400ms;
    transition: transform 400ms, -webkit-transform 400ms; }
  .nav-tabs .nav-link.active:before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }

.nav-tabs:not([class*="nav-tabs--"]) .nav-link.active {
  color: #2196F3; }

.tab-content {
  padding: 1.5rem 0; }

.nav-tabs--red .nav-link.active {
  color: #ff6b68; }

.nav-tabs--red .nav-link:before {
  background-color: #ff6b68; }

.nav-tabs--green .nav-link.active {
  color: #32c787; }

.nav-tabs--green .nav-link:before {
  background-color: #32c787; }

.nav-tabs--amber .nav-link.active {
  color: #FF9800; }

.nav-tabs--amber .nav-link:before {
  background-color: #FF9800; }

.nav-tabs--black .nav-link.active {
  color: #000000; }

.nav-tabs--black .nav-link:before {
  background-color: #000000; }

.tooltip {
  font-size: 0.95rem;
  font-weight: 500; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/roboto/Roboto-Light-webfont.eot");
  src: url("../fonts/roboto/Roboto-Light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Light-webfont.woff") format("woff"), url("../fonts/roboto/Roboto-Light-webfont.ttf") format("truetype"), url("../fonts/roboto/Roboto-Light-webfont.svg#icon") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/roboto/Roboto-Regular-webfont.eot");
  src: url("../fonts/roboto/Roboto-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Regular-webfont.woff") format("woff"), url("../fonts/roboto/Roboto-Regular-webfont.ttf") format("truetype"), url("../fonts/roboto/Roboto-Regular-webfont.svg#icon") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/roboto/Roboto-Medium-webfont.eot");
  src: url("../fonts/roboto/Roboto-Medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Medium-webfont.woff") format("woff"), url("../fonts/roboto/Roboto-Medium-webfont.ttf") format("truetype"), url("../fonts/roboto/Roboto-Medium-webfont.svg#icon") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/roboto/Roboto-Bold-webfont.eot");
  src: url("../fonts/roboto/Roboto-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Bold-webfont.woff") format("woff"), url("../fonts/roboto/Roboto-Bold-webfont.ttf") format("truetype"), url("../fonts/roboto/Roboto-Bold-webfont.svg#icon") format("svg");
  font-weight: 700;
  font-style: normal; }

.bg-blue {
  background-color: #2196F3 !important; }

.text-blue {
  color: #2196F3 !important; }

.bg-indigo {
  background-color: #3F51B5 !important; }

.text-indigo {
  color: #3F51B5 !important; }

.bg-purple {
  background-color: #d066e2 !important; }

.text-purple {
  color: #d066e2 !important; }

.bg-pink {
  background-color: #ff85af !important; }

.text-pink {
  color: #ff85af !important; }

.bg-red {
  background-color: #ff6b68 !important; }

.text-red {
  color: #ff6b68 !important; }

.bg-orange {
  background-color: #FF9800 !important; }

.text-orange {
  color: #FF9800 !important; }

.bg-yellow {
  background-color: #FFEB3B !important; }

.text-yellow {
  color: #FFEB3B !important; }

.bg-green {
  background-color: #32c787 !important; }

.text-green {
  color: #32c787 !important; }

.bg-teal {
  background-color: #39bbb0 !important; }

.text-teal {
  color: #39bbb0 !important; }

.bg-cyan {
  background-color: #00BCD4 !important; }

.text-cyan {
  color: #00BCD4 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.text-white {
  color: #FFFFFF !important; }

.bg-gray {
  background-color: #868e96 !important; }

.text-gray {
  color: #868e96 !important; }

.bg-gray-dark {
  background-color: #343a40 !important; }

.text-gray-dark {
  color: #343a40 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.text-white {
  color: #FFFFFF !important; }

.bg-black {
  background-color: #000000 !important; }

.text-black {
  color: #000000 !important; }

.bg-red {
  background-color: #ff6b68 !important; }

.text-red {
  color: #ff6b68 !important; }

.bg-pink {
  background-color: #ff85af !important; }

.text-pink {
  color: #ff85af !important; }

.bg-purple {
  background-color: #d066e2 !important; }

.text-purple {
  color: #d066e2 !important; }

.bg-deep-purple {
  background-color: #673AB7 !important; }

.text-deep-purple {
  color: #673AB7 !important; }

.bg-indigo {
  background-color: #3F51B5 !important; }

.text-indigo {
  color: #3F51B5 !important; }

.bg-blue {
  background-color: #2196F3 !important; }

.text-blue {
  color: #2196F3 !important; }

.bg-light-blue {
  background-color: #03A9F4 !important; }

.text-light-blue {
  color: #03A9F4 !important; }

.bg-cyan {
  background-color: #00BCD4 !important; }

.text-cyan {
  color: #00BCD4 !important; }

.bg-teal {
  background-color: #39bbb0 !important; }

.text-teal {
  color: #39bbb0 !important; }

.bg-green {
  background-color: #32c787 !important; }

.text-green {
  color: #32c787 !important; }

.bg-light-green {
  background-color: #8BC34A !important; }

.text-light-green {
  color: #8BC34A !important; }

.bg-lime {
  background-color: #CDDC39 !important; }

.text-lime {
  color: #CDDC39 !important; }

.bg-yellow {
  background-color: #FFEB3B !important; }

.text-yellow {
  color: #FFEB3B !important; }

.bg-amber {
  background-color: #ffc721 !important; }

.text-amber {
  color: #ffc721 !important; }

.bg-orange {
  background-color: #FF9800 !important; }

.text-orange {
  color: #FF9800 !important; }

.bg-deep-orange {
  background-color: #FF5722 !important; }

.text-deep-orange {
  color: #FF5722 !important; }

.bg-brown {
  background-color: #795548 !important; }

.text-brown {
  color: #795548 !important; }

.bg-blue-grey {
  background-color: #607D8B !important; }

.text-blue-grey {
  color: #607D8B !important; }

/*
 * Restore Bootstrap 3 "hidden" utility classes.
 */
/* Breakpoint XS */
@media (max-width: 576px) {
  .hidden-xs-down, .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up,
  .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl {
    display: none !important; } }

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up,
  .hidden-unless-xs, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl {
    display: none !important; } }

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-lg, .hidden-unless-xl {
    display: none !important; } }

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-xl {
    display: none !important; } }

/* Breakpoint XL */
@media (min-width: 1200px) {
  .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg {
    display: none !important; } }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  *:focus, *:active {
    outline: none !important; }

html {
  font-size: 13.5px; }

a {
  cursor: pointer; }

pre {
  background-color: #343a40;
  border-radius: 2px;
  padding: 1.5rem; }

strong, b {
  font-weight: 500; }

button,
input,
optgroup,
select,
textarea {
  font-family: "Roboto", sans-serif; }

.list {
  list-style: none;
  padding-left: 0; }
  .list > li:before {
    font-family: "Material-Design-Iconic-Font";
    margin-right: 1.1rem; }

.list--star > li:before {
  content: '\f27d'; }

.list--check > li:before {
  content: '\f26b'; }

.list--dot > li:before {
  content: '\f26f'; }

.main {
  position: relative; }

.main--alt {
  padding-top: 40px; }

@media (min-width: 1200px) {
  .content:not(.content--boxed):not(.content--full) {
    padding: 102px 30px 0 270px; } }

@media (min-width: 576px) and (max-width: 1199.98px) {
  .content:not(.content--boxed):not(.content--full) {
    padding: 102px 30px 0; } }

@media (max-width: 575.98px) {
  .content:not(.content--boxed):not(.content--full) {
    padding: 87px 15px 0; } }

@media (min-width: 576px) {
  .content--full {
    padding: 102px 30px 0; } }

@media (max-width: 767.98px) {
  .content--full {
    padding: 87px 15px 0; } }

.content__inner {
  margin: auto; }
  .content__inner:not(.content__inner--sm) {
    max-width: 1280px; }

.content__inner--sm {
  max-width: 800px; }

.content__title {
  margin-bottom: 2rem;
  padding: 1.5rem 2rem 0;
  position: relative; }
  .content__title > h1 {
    line-height: 100%;
    font-weight: normal;
    font-size: 1.15rem;
    margin: 0;
    text-transform: uppercase;
    color: #676767; }
  .content__title .actions {
    position: absolute;
    top: 0.3rem;
    right: 1rem; }
  .content__title > small {
    font-size: 1rem;
    display: block;
    margin-top: 0.8rem;
    color: #959595; }

[data-columns]::after {
  display: block;
  clear: both;
  content: ""; }

@media (min-width: 1500px) {
  [data-columns]:before {
    content: '3 .column.size-1of3'; } }

@media (min-width: 768px) {
  [data-columns] {
    margin: 0 -15px; }
    [data-columns] .column {
      padding: 0 15px; } }

@media (min-width: 768px) and (max-width: 1499px) {
  [data-columns]:before {
    content: '2 .column.size-1of2'; } }

@media screen and (max-width: 767px) {
  [data-columns] {
    margin: 0 -10px; }
    [data-columns] .column {
      padding: 0 10px; }
    [data-columns]:before {
      content: '1 .column.size-1of1'; } }

.column {
  float: left; }

.size-1of1 {
  width: 100%; }

.size-1of2 {
  width: 50%; }

.size-1of3 {
  width: 33.333%; }

.view-more {
  display: block;
  text-transform: uppercase;
  padding: 1.1rem 0;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  transition: color 300ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    .view-more {
      transition: none; } }
  .view-more:not(.view-more--light) {
    color: #9c9c9c; }
    .view-more:not(.view-more--light):hover {
      color: #838282; }

.view-more--light {
  color: #FFFFFF; }
  .view-more--light:hover {
    color: rgba(255, 255, 255, 0.8); }

.load-more {
  text-align: center;
  margin-top: 2rem; }
  .load-more > a {
    display: inline-block;
    padding: 0.5rem 1rem;
    border: 2px solid rgba(0, 0, 0, 0.065);
    border-radius: 2px;
    color: #747a80;
    transition: border-color 300ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .load-more > a {
        transition: none; } }
    .load-more > a > i {
      font-size: 1.2rem;
      vertical-align: middle;
      margin: 0 0.3rem 0 -0.1rem;
      transition: -webkit-transform 300ms;
      transition: transform 300ms;
      transition: transform 300ms, -webkit-transform 300ms; }
      @media screen and (prefers-reduced-motion: reduce) {
        .load-more > a > i {
          transition: none; } }
    .load-more > a:hover {
      border-color: rgba(0, 0, 0, 0.12); }
      .load-more > a:hover > i {
        -webkit-transform: rotate(-360deg);
                transform: rotate(-360deg); }

.card-body .view-more {
  padding: 1rem 0 0; }

.actions:not(.actions--inverse) .actions__item {
  color: #a9adb1; }
  .actions:not(.actions--inverse) .actions__item:hover {
    color: #747a80; }
  .actions:not(.actions--inverse) .actions__item.actions__item--active {
    color: #5c6165; }

.actions__item {
  display: inline-block;
  vertical-align: top;
  line-height: 31px;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 300ms;
  margin: 0 2px;
  width: 30px;
  height: 30px; }
  .actions__item > i {
    display: inline-block;
    width: 100%; }

.actions--inverse .actions__item {
  color: rgba(255, 255, 255, 0.7); }
  .actions--inverse .actions__item:hover {
    color: #FFFFFF; }

.actions--inverse .actions__item--active {
  color: #FFFFFF; }

.icon-list {
  list-style: none;
  padding: 0;
  margin: 0; }
  .icon-list > li {
    position: relative;
    padding: 0.3rem 0; }
    .icon-list > li address {
      display: inline-block;
      vertical-align: top; }
    .icon-list > li > i {
      width: 2.5rem;
      text-align: center;
      font-size: 1.25rem;
      top: 0.12rem;
      position: relative;
      margin-left: -0.5rem; }

.avatar-img,
.avatar-char {
  border-radius: 50%;
  width: 3rem;
  height: 3rem; }

.avatar-char {
  line-height: 3rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  color: #FFFFFF;
  font-style: normal; }
  .avatar-char > .zmdi {
    line-height: 3rem; }

.ma-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 18; }

.tags > a {
  color: #747a80;
  border: 2px solid #dee2e6;
  border-radius: 2px;
  padding: 0.45rem 0.8rem;
  display: inline-block;
  margin: 0 0.1rem 0.4rem;
  transition: color 300ms, border-color 300ms; }
  .tags > a:hover {
    color: #5c6165;
    border-color: #c1c9d0; }

.header {
  position: fixed;
  width: 100%;
  height: 72px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15);
  color: #FFFFFF;
  padding: 0 2rem;
  z-index: 20;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0; }
  .header::after {
    display: block;
    clear: both;
    content: ""; }
  .header .ma-backdrop {
    position: absolute; }

.navigation-trigger {
  float: left;
  padding: 2rem 2rem 2rem 2.4rem;
  margin-left: -2rem;
  cursor: pointer; }
  .navigation-trigger.toggled .navigation-trigger__inner {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    .navigation-trigger.toggled .navigation-trigger__inner:before {
      -webkit-transform: scale(1);
              transform: scale(1); }
  .navigation-trigger.toggled .navigation-trigger__line:first-child {
    width: 12px;
    -webkit-transform: translateX(8px) translateY(1px) rotate(45deg);
            transform: translateX(8px) translateY(1px) rotate(45deg); }
  .navigation-trigger.toggled .navigation-trigger__line:last-child {
    width: 11px;
    -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg);
            transform: translateX(8px) translateY(-1px) rotate(-45deg); }

.navigation-trigger__inner,
.navigation-trigger__line {
  width: 18px;
  transition: all 300ms; }

.navigation-trigger__inner {
  position: relative; }
  .navigation-trigger__inner:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    left: -11px;
    top: -14px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    transition: all 300ms;
    -webkit-transform: scale(0);
            transform: scale(0); }

.navigation-trigger__line {
  height: 2px;
  background-color: #FFFFFF;
  display: block;
  position: relative; }
  .navigation-trigger__line:not(:last-child) {
    margin-bottom: 3px; }

@media (min-width: 1200px) {
  .header__logo {
    min-width: calc(270px - 2rem); } }

.header__logo > h1 {
  line-height: 100%;
  font-size: 1.3rem;
  font-weight: normal;
  margin: 0; }
  .header__logo > h1 > a {
    color: #FFFFFF; }

.top-nav {
  list-style: none;
  margin: 0 0 0 auto;
  padding: 0; }
  .top-nav > li {
    display: inline-block;
    vertical-align: middle; }
    .top-nav > li > a {
      display: block;
      color: #FFFFFF;
      border-radius: 2px;
      text-align: center;
      line-height: 100%;
      position: relative;
      transition: background-color 300ms; }
      @media screen and (prefers-reduced-motion: reduce) {
        .top-nav > li > a {
          transition: none; } }
      .top-nav > li > a:not(.header__nav__text) {
        padding: 0.5rem 0.15rem;
        min-width: 50px; }
        .top-nav > li > a:not(.header__nav__text) > .zmdi {
          font-size: 1.65rem;
          line-height: 100%; }
      .top-nav > li > a.active, .top-nav > li > a:hover {
        background-color: rgba(255, 255, 255, 0.2); }
    .top-nav > li .dropdown-menu--block {
      padding: 0; }
    @media (max-width: 575.98px) {
      .top-nav > li {
        position: static; }
        .top-nav > li .dropdown-menu--block {
          left: 20px;
          width: calc(100% - 40px);
          top: 62px; } }

.top-nav__notifications .listview {
  position: relative; }
  .top-nav__notifications .listview:before {
    font-family: "Material-Design-Iconic-Font";
    content: "";
    font-size: 2.5rem;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 90px;
    height: 90px;
    border: 2px solid #ececec;
    color: #8e9499;
    border-radius: 50%;
    -webkit-transform: scale(0) rotate(-360deg);
            transform: scale(0) rotate(-360deg);
    opacity: 0;
    text-align: center;
    line-height: 86px; }

.top-nav__notifications .listview__scroll {
  height: 350px; }

.top-nav__notifications--cleared .listview:before {
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  opacity: 1; }

.top-nav__notify:before {
  content: '';
  width: 7px;
  height: 7px;
  background-color: #ff7572;
  color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: 0;
  left: 0;
  margin: auto;
  -webkit-animation-name: flash;
  animation-name: flash;
  -webkit-animation-duration: 2000ms;
  animation-duration: 2000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.search {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 2.5rem;
  position: relative; }
  @media (max-width: 1199.98px) {
    .search {
      padding: 0 1.5rem;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: #FFFFFF;
      z-index: 21;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      transition: -webkit-transform 300ms;
      transition: transform 300ms;
      transition: transform 300ms, -webkit-transform 300ms; }
      .search:not(.search--toggled) {
        -webkit-transform: translate3d(0, -105%, 0);
                transform: translate3d(0, -105%, 0); } }

.search__inner {
  position: relative; }
  @media (max-width: 1199.98px) {
    .search__inner {
      max-width: 600px;
      margin: 0 auto;
      width: 100%; } }

.search__text {
  border: 0;
  border-radius: 2px;
  height: 2.9rem;
  padding: 0 1rem 0 3rem;
  width: 100%;
  transition: background-color 300ms, color 300ms; }
  @media (min-width: 992px) {
    .search__text {
      background-color: rgba(255, 255, 255, 0.2);
      color: #FFFFFF; }
      .search__text::-webkit-input-placeholder {
        color: #FFFFFF;
        opacity: 1; }
      .search__text:-ms-input-placeholder {
        color: #FFFFFF;
        opacity: 1; }
      .search__text::placeholder {
        color: #FFFFFF;
        opacity: 1; }
      .search__text:focus {
        background-color: #FFFFFF;
        color: #495057; }
        .search__text:focus::-webkit-input-placeholder {
          color: #606a73;
          opacity: 1; }
        .search__text:focus:-ms-input-placeholder {
          color: #606a73;
          opacity: 1; }
        .search__text:focus::placeholder {
          color: #606a73;
          opacity: 1; } }
  @media (max-width: 1199.98px) {
    .search__text {
      background-color: #f6f6f6;
      color: #495057; }
      .search__text::-webkit-input-placeholder {
        color: #606a73;
        opacity: 1; }
      .search__text:-ms-input-placeholder {
        color: #606a73;
        opacity: 1; }
      .search__text::placeholder {
        color: #606a73;
        opacity: 1; } }

.search__helper {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.3rem;
  height: 100%;
  width: 3rem;
  text-align: center;
  line-height: 3rem;
  cursor: pointer;
  transition: color 300ms, -webkit-transform 300ms ease-out;
  transition: color 300ms, transform 300ms ease-out;
  transition: color 300ms, transform 300ms ease-out, -webkit-transform 300ms ease-out; }
  @media (max-width: 1199.98px) {
    .search__helper {
      color: #495057;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      line-height: 2.9rem; }
      .search__helper:before {
        content: '\f301'; }
      .search__helper:hover {
        opacity: 0.9; } }

.search--focus .search__helper {
  color: #606a73;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  line-height: 2.9rem; }
  .search--focus .search__helper:before {
    content: '\f301'; }

.app-shortcuts {
  margin: 0;
  padding: 1rem; }

.app-shortcuts__item {
  text-align: center;
  padding: 1rem 0;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  transition: background-color 300ms; }
  .app-shortcuts__item:hover > small {
    color: #FFFFFF; }
  .app-shortcuts__item:hover > i {
    background-color: rgba(255, 255, 255, 0.15); }
  .app-shortcuts__item:hover .app-shortcuts__helper {
    -webkit-transform: scale(3.5);
            transform: scale(3.5); }
  .app-shortcuts__item > i {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: #FFFFFF;
    line-height: 45px;
    font-size: 1.5rem;
    transition: background-color 500ms; }
  .app-shortcuts__item > small {
    display: block;
    margin-top: 0.5rem;
    font-size: 0.95rem;
    color: #9c9c9c;
    transition: color 500ms; }

.app-shortcuts__helper {
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  top: 13px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.top-menu {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15);
  padding: 0 1rem 0.5rem;
  list-style: none;
  text-align: center; }
  .top-menu > li {
    display: inline-block; }
    .top-menu > li.active {
      position: relative;
      box-shadow: 0 0 0 -2px red; }
    .top-menu > li > a {
      line-height: 100%;
      color: rgba(255, 255, 255, 0.65);
      font-weight: 500;
      text-transform: uppercase;
      padding: 1rem;
      display: block;
      transition: color 300ms; }
      .top-menu > li > a:hover {
        color: #FFFFFF; }
    .top-menu > li.active > a {
      color: #FFFFFF; }

.footer {
  text-align: center;
  padding: 4rem 1rem 1rem; }
  .footer > p {
    color: #a9adb1;
    margin-bottom: 0; }

.footer__nav {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .footer__nav .nav-link {
    color: #a9adb1;
    transition: color 300ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .footer__nav .nav-link {
        transition: none; } }
    .footer__nav .nav-link:hover, .footer__nav .nav-link:focus {
      color: #81878d; }
    .footer__nav .nav-link + .nav-link:before {
      font-family: "Material-Design-Iconic-Font";
      content: "";
      font-size: 4px;
      position: relative;
      left: -1.2rem;
      top: -0.2rem;
      color: #a9adb1; }

.sidebar {
  width: 270px;
  position: fixed;
  left: 0;
  padding: 102px 2rem 0.5rem 2rem;
  height: 100%;
  overflow: hidden;
  z-index: 19; }
  @media (max-width: 1199.98px) {
    .sidebar {
      background-color: #FFFFFF;
      transition: opacity 300ms, -webkit-transform 300ms;
      transition: transform 300ms, opacity 300ms;
      transition: transform 300ms, opacity 300ms, -webkit-transform 300ms; }
      .sidebar:not(.toggled) {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0); }
      .sidebar.toggled {
        box-shadow: 5px 0 10px rgba(0, 0, 0, 0.08);
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0); } }
  .sidebar .scrollbar-inner > .scroll-element {
    margin-right: 0; }

.sidebar--hidden {
  background-color: #FFFFFF;
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: transform 300ms, opacity 300ms;
  transition: transform 300ms, opacity 300ms, -webkit-transform 300ms; }
  .sidebar--hidden:not(.toggled) {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  .sidebar--hidden.toggled {
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.08);
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }

.user {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  margin: 0 0 1.5rem 0;
  position: relative; }
  .user .dropdown-menu {
    width: 100%;
    -webkit-transform: none !important;
            transform: none !important; }

.user__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.8rem;
  border-radius: 2px;
  transition: background-color 300ms; }
  .user__info:hover {
    background-color: rgba(0, 0, 0, 0.04); }

.user__img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.8rem; }

.user__name {
  color: #333;
  font-weight: 500; }

.user__email {
  color: #9c9c9c;
  margin-top: 0.1rem;
  line-height: 100%; }

.navigation {
  list-style: none;
  padding: 0; }
  .navigation li a {
    color: #747a80;
    transition: background-color 300ms, color 300ms;
    font-weight: 500;
    display: block; }
  .navigation li:not(.navigation__active):not(.navigation__sub--active) a:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: #5c6165; }
  .navigation > li > a {
    padding: 0.85rem 0.5rem;
    position: relative;
    border-radius: 2px; }
    .navigation > li > a > i {
      vertical-align: top;
      font-size: 1.3rem;
      position: relative;
      top: 0.1rem;
      width: 1.5rem;
      text-align: center;
      margin-right: 0.6rem; }

.navigation__sub > ul {
  border-radius: 2px;
  list-style: none;
  overflow: hidden;
  padding: 0; }
  .navigation__sub > ul > li > a {
    padding: 0.6rem 1rem 0.6rem 2.75rem; }
  .navigation__sub > ul > li:last-child {
    padding-bottom: 0.8rem; }

.navigation__sub:not(.navigation__sub--active) > ul {
  display: none; }

.navigation__sub .navigation__active {
  position: relative; }
  .navigation__sub .navigation__active:before {
    font-family: "Material-Design-Iconic-Font";
    content: "";
    font-size: 6px;
    position: absolute;
    left: 1rem;
    top: 1.1rem; }

.chat {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  background-color: #FFFFFF;
  z-index: 21;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.08);
  padding-top: 30px;
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: transform 300ms, opacity 300ms;
  transition: transform 300ms, opacity 300ms, -webkit-transform 300ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    .chat {
      transition: none; } }
  .chat:not(.toggled) {
    -webkit-transform: translate3d(340px, 0, 0);
            transform: translate3d(340px, 0, 0);
    opacity: 0; }
  .chat.toggled {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; }

.chat__header {
  padding: 0 2rem; }

.chat__title {
  line-height: 100%;
  margin: 0 0 2rem;
  font-size: 1.2rem; }
  .chat__title > small {
    color: #9c9c9c;
    font-size: 0.95rem;
    display: block;
    margin-top: 0.5rem;
    text-transform: none; }

.chat__search .form-group:before {
  font-family: "Material-Design-Iconic-Font";
  content: "";
  font-size: 1.4rem;
  position: absolute;
  left: 0;
  bottom: 0.3rem; }

.chat__search .form-control {
  padding-left: 2rem; }

.chat__buddies {
  height: calc(100vh - 150px); }
  .chat__buddies .listview__item {
    padding-left: 3rem; }

.chat__available,
.chat__away,
.chat__busy {
  position: relative; }
  .chat__available:before,
  .chat__away:before,
  .chat__busy:before {
    position: absolute;
    height: 8px;
    width: 8px;
    content: '';
    border-radius: 50%;
    left: 1.5rem;
    top: 0;
    bottom: 0;
    margin: auto; }

.chat__available:before {
  background-color: #32c787; }

.chat__away:before {
  background-color: #FF9800; }

.chat__busy:before {
  background-color: #ff6b68; }

.toggle-switch {
  display: inline-block;
  width: 36px;
  height: 20px;
  position: relative; }

.toggle-switch__helper {
  position: absolute;
  height: 12px;
  width: 100%; }
  .toggle-switch__helper:before, .toggle-switch__helper:after {
    content: '';
    position: absolute;
    left: 0;
    transition: left 300ms, background-color, 300ms; }
  .toggle-switch__helper:before {
    background-color: #e0e0e0;
    top: 4px;
    height: 100%;
    width: 100%;
    border-radius: 10px; }
  .toggle-switch__helper:after {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FFFFFF;
    left: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 1; }

.toggle-switch__checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer; }
  .toggle-switch__checkbox:checked ~ .toggle-switch__helper:after {
    left: calc(100% - 20px);
    background-color: #39bbb0; }
  .toggle-switch__checkbox:disabled ~ .toggle-switch__helper {
    opacity: 0.65; }
  .toggle-switch__checkbox:active ~ .toggle-switch__helper:after {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05); }

.toggle-switch--red .toggle-switch__checkbox:checked ~ .toggle-switch__helper:after {
  background-color: #ff6b68; }

.toggle-switch--blue .toggle-switch__checkbox:checked ~ .toggle-switch__helper:after {
  background-color: #2196F3; }

.toggle-switch--green .toggle-switch__checkbox:checked ~ .toggle-switch__helper:after {
  background-color: #32c787; }

.toggle-switch--amber .toggle-switch__checkbox:checked ~ .toggle-switch__helper:after {
  background-color: #FF9800; }

.toggle-switch--purple .toggle-switch__checkbox:checked ~ .toggle-switch__helper:after {
  background-color: #d066e2; }

.toggle-switch--cyan .toggle-switch__checkbox:checked ~ .toggle-switch__helper:after {
  background-color: #00BCD4; }

.listview__header {
  color: #333;
  text-transform: uppercase;
  padding: 1.2rem 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
  text-align: center; }
  .listview__header .actions {
    position: absolute;
    top: 0.8rem;
    right: 1rem; }

.listview__scroll {
  overflow-y: auto; }

.listview__item {
  padding: 1.25rem 2.2rem;
  transition: background-color 300ms; }
  .listview__item > .avatar-char {
    margin-right: 1.2rem; }

.listview:not(.listview--block) .listview__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.listview__img {
  height: 3rem;
  border-radius: 50%;
  vertical-align: top;
  margin: 0 1.2rem 0 0; }

.listview__content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 0; }
  .listview__content > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #8e9499;
    margin-bottom: 0; }

.listview__heading {
  font-size: 1rem;
  color: #333;
  position: relative; }
  .listview__heading > small {
    float: right;
    color: #9c9c9c;
    font-weight: 500;
    font-size: 0.85rem;
    margin-top: 0.1rem; }
  .listview__heading + p {
    margin: 0.2rem 0 0;
    font-size: 0.95rem; }

.listview__attrs {
  -webkit-box-flex: 1;
      -ms-flex: 1 100%;
          flex: 1 100%;
  margin-top: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .listview__attrs > span {
    padding: 0.55rem 0.7rem;
    border: 1px solid #e9ecef;
    display: inline-block;
    line-height: 100%;
    font-size: 0.9rem;
    margin: 0.2rem 0.25rem 0.055rem 0;
    background-color: #FFFFFF; }

.listview__checkbox {
  margin-right: 0.5rem; }

.listview:not(.listview--inverse).listview--hover .listview__item:hover {
  background-color: #f9f9f9; }

.listview:not(.listview--inverse).listview--striped .listview__item:nth-child(even) {
  background-color: #f9f9f9; }

.listview:not(.listview--inverse) .listview__item--active {
  background-color: #f9f9f9; }

.listview:not(.listview--inverse).listview--bordered .listview__item + .listview__item {
  border-top: 1px solid #f6f6f6; }

.listview--inverse.listview--striped .listview__item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1); }

.listview__actions {
  margin-left: auto;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-right: -1rem; }

.toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.05rem 2.2rem 0;
  position: relative; }
  .toolbar:not(.toolbar--inner) {
    background-color: #FFFFFF;
    border-radius: 2px;
    margin-bottom: 30px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075); }
  .toolbar .actions {
    margin: 0.05rem -0.8rem 0 auto; }

.toolbar--inner {
  border-bottom: 1px solid #f6f6f6;
  margin-bottom: 1rem;
  border-radius: 2px 2px 0 0; }

.toolbar__nav {
  white-space: nowrap;
  overflow-x: auto; }
  .toolbar__nav > a {
    color: #9c9c9c;
    display: inline-block;
    transition: color 300ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .toolbar__nav > a {
        transition: none; } }
    .toolbar__nav > a + a {
      padding-left: 1rem; }
    .toolbar__nav > a.active, .toolbar__nav > a:hover {
      color: #333; }

.toolbar__search {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  border-radius: 2px;
  padding-left: 3rem;
  display: none; }
  .toolbar__search input[type=text] {
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0 1.6rem;
    font-size: 1.1rem;
    color: #495057; }
    .toolbar__search input[type=text]::-webkit-input-placeholder {
      color: #9c9c9c; }
    .toolbar__search input[type=text]:-moz-placeholder {
      color: #9c9c9c; }
    .toolbar__search input[type=text]::-moz-placeholder {
      color: #9c9c9c; }
    .toolbar__search input[type=text]:-ms-input-placeholder {
      color: #9c9c9c; }

.toolbar__search__close {
  transition: color 300ms;
  cursor: pointer;
  position: absolute;
  top: 1.9rem;
  left: 1.8rem;
  font-size: 1.5rem;
  color: #9c9c9c; }
  @media screen and (prefers-reduced-motion: reduce) {
    .toolbar__search__close {
      transition: none; } }
  .toolbar__search__close:hover {
    color: #747a80; }

.toolbar__label {
  margin: 0;
  font-size: 1.1rem; }

.page-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f3f3f3;
  z-index: 999999999;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.page-loader__spinner {
  position: relative;
  width: 50px;
  height: 50px; }
  .page-loader__spinner svg {
    -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
    .page-loader__spinner svg circle {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
              animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@-webkit-keyframes color {
  100%,
  0% {
    stroke: #ff6b68; }
  40% {
    stroke: #2196F3; }
  66% {
    stroke: #32c787; }
  80%,
  90% {
    stroke: #FF9800; } }

@keyframes color {
  100%,
  0% {
    stroke: #ff6b68; }
  40% {
    stroke: #2196F3; }
  66% {
    stroke: #32c787; }
  80%,
  90% {
    stroke: #FF9800; } }

@media (min-width: 768px) {
  .profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; } }

@media (max-width: 767.98px) {
  .profile {
    margin-top: 75px;
    text-align: center; } }

.profile__img {
  padding: 5px;
  position: relative; }
  .profile__img img {
    max-width: 200px;
    border-radius: 2px; }
  @media (max-width: 767.98px) {
    .profile__img img {
      margin: -55px 0 -10px;
      width: 120px;
      border: 5px solid #FFFFFF;
      border-radius: 50%; } }

.profile__img__edit {
  position: absolute;
  font-size: 1.2rem;
  top: 15px;
  left: 15px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  color: #FFFFFF; }
  .profile__img__edit:hover {
    background-color: rgba(0, 0, 0, 0.65);
    color: #FFFFFF; }

.profile__info {
  padding: 30px; }

.photos {
  margin: 0 -3px 1rem; }
  .photos > a {
    padding: 0;
    border: 3px solid transparent; }
    .photos > a img {
      border-radius: 2px;
      width: 100%; }

@media (max-width: 575.98px) {
  .contacts {
    margin: 0 -5px; }
    .contacts > [class*="col-"] {
      padding: 0 5px; } }

.contacts__item {
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  padding: 1.8rem 1.5rem 1.15rem;
  text-align: center;
  margin-bottom: 30px; }
  .contacts__item:hover .contacts__img > img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  @media (max-width: 575.98px) {
    .contacts__item {
      margin-bottom: 10px; } }

.contacts__img {
  display: block;
  margin-bottom: 1.1rem; }
  .contacts__img > img {
    max-width: 120px;
    max-height: 120px;
    width: 100%;
    border-radius: 50%;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms; }

.contacts__info strong,
.contacts__info small {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block; }

.contacts__info strong {
  font-weight: normal;
  color: #333; }

.contacts__info small {
  font-size: 0.9rem;
  color: #9c9c9c; }

.contacts__btn {
  margin-top: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.9rem;
  border: 0;
  line-height: 100%;
  background-color: transparent;
  color: #747a80;
  cursor: pointer;
  padding: 0.75rem 1rem;
  border-radius: 2px;
  transition: background-color 300ms, color 300ms; }
  .contacts__btn:hover {
    background-color: #f6f6f6;
    color: #333; }

.new-contact__header {
  background-color: #f3f3f3;
  text-align: center;
  padding: 2.5rem 0;
  border-radius: 2px 2px 0 0;
  border: 0.35rem solid #FFFFFF;
  position: relative; }

.new-contact__img {
  border-radius: 50%;
  box-shadow: 0 0 0 0.35rem #FFFFFF;
  width: 150px;
  height: 150px; }
  @media (max-width: 767.98px) {
    .new-contact__img {
      width: 100px;
      height: 100px; } }

.new-contact__upload {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 42px;
  background-color: #ced4da;
  color: #FFFFFF;
  transition: background-color 300ms; }
  .new-contact__upload:hover {
    color: #FFFFFF;
    background-color: #adb5bd; }

@media (max-width: 575.98px) {
  .groups {
    margin: 0 -5px; }
    .groups [class*=col-] {
      padding: 0 5px; }
    .groups .groups__item {
      margin-bottom: 10px; } }

.groups__item {
  position: relative;
  text-align: center;
  padding: 2rem 1rem 1.5rem;
  margin-bottom: 30px;
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075); }
  .groups__item:hover .actions {
    opacity: 1; }
  .groups__item .actions {
    position: absolute;
    top: 0.7rem;
    right: 0.5rem;
    z-index: 1;
    opacity: 0; }

.groups__img {
  width: 6.2rem;
  display: inline-block; }
  .groups__img .avatar-img {
    display: inline-block;
    margin: 0 -1px 3px 0;
    vertical-align: top; }

.groups__info {
  margin-top: 1rem; }
  .groups__info > strong {
    color: #333;
    display: block;
    font-weight: 500; }
  .groups__info > small {
    font-size: 0.9rem;
    color: #9c9c9c; }

.messages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  height: calc(100vh - 180px); }

.messages__sidebar {
  width: 23rem;
  overflow: hidden; }
  @media (min-width: 768px) {
    .messages__sidebar {
      border-right: 1px solid #f6f6f6; } }
  @media (max-width: 991.98px) {
    .messages__sidebar {
      display: none; } }
  .messages__sidebar .listview {
    height: calc(100% - 130px);
    overflow-y: auto; }

.messages__search {
  padding: 0 2.2rem;
  position: relative; }
  .messages__search .form-group:before {
    font-family: "Material-Design-Iconic-Font";
    content: "";
    font-size: 1.3rem;
    position: absolute;
    left: 0;
    bottom: 0.15rem; }
  .messages__search .form-control {
    padding-left: 2rem; }

.messages__body {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.messages__header,
.messages__reply {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }

.messages__content {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  overflow: hidden;
  height: 100%; }
  @media (min-width: 768px) {
    .messages__content {
      padding: 2.5rem; } }
  @media (max-width: 767.98px) {
    .messages__content {
      padding: 1.5rem; } }

.messages__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem; }
  .messages__item:not(.messages__item--right) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .messages__item:not(.messages__item--right) .messages__details {
      padding-left: 1rem; }

.messages__details {
  max-width: 500px; }
  .messages__details > p {
    border-radius: 2px;
    padding: 1rem 1.3rem;
    margin-bottom: 0;
    display: inline-block;
    text-align: left; }
    .messages__details > p + p {
      margin-top: 2px; }
  .messages__details > small {
    display: block;
    padding: 0 1rem;
    margin-top: 1rem;
    color: #9c9c9c;
    font-size: 0.9rem; }
    .messages__details > small > .zmdi {
      font-size: 1.2rem;
      vertical-align: middle;
      margin-right: 0.3rem; }
  .messages__details:not(.messages__details--highlight) > p {
    background-color: #f9f9f9; }

.messages__item--right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  .messages__item--right .messages__details {
    text-align: right; }
    .messages__item--right .messages__details > p {
      background-color: #2196F3;
      color: #FFFFFF;
      margin-left: auto; }

.messages__reply {
  border-top: 1px solid #f6f6f6;
  position: relative; }

.messages__reply__text {
  height: 50px;
  width: 100%;
  margin-bottom: -5px;
  border: 0;
  border-radius: 2px;
  padding: 1rem 1.5rem;
  resize: none;
  background-color: transparent;
  color: #495057; }

.price-table {
  text-align: center; }
  .price-table:not(.price-table--highlight) {
    margin: 0 -10px; }
    .price-table:not(.price-table--highlight) > [class*="col-"] {
      padding: 0 10px;
      text-align: center; }

.price-table--highlight {
  margin: 0; }
  .price-table--highlight > [class*="col-"] {
    padding: 0; }

.price-table__item {
  margin-bottom: 20px;
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075); }
  @media (max-width: 767.98px) {
    .price-table__item {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto; } }

@media (min-width: 768px) {
  .price-table__item--popular {
    padding-bottom: 1rem;
    position: relative;
    z-index: 1;
    margin: -1rem -0.1rem 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.14); }
    .price-table__item--popular .price-table__header {
      padding: 2.5rem 2rem 2.5rem; } }

.price-table__header {
  color: #FFFFFF;
  border-radius: 2px 2px 0 0;
  padding: 2rem;
  margin-bottom: 2rem; }

.price-table__title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.3rem; }

.price-table__desc {
  color: rgba(255, 255, 255, 0.75);
  margin: 0.3rem 0; }

.price-table__price {
  font-size: 1.8rem; }
  .price-table__price > small {
    font-size: 1rem;
    position: relative;
    top: -0.4rem; }

.price-table__info {
  padding-left: 0;
  list-style: none;
  padding: 1rem 0; }
  .price-table__info > li {
    font-weight: 500;
    padding: 1rem 1.5rem; }
    .price-table__info > li + li {
      border-top: 1px solid #f6f6f6; }

.price-table__action {
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  padding: 0.8rem 1.2rem;
  border-radius: 2px;
  color: #FFFFFF;
  font-weight: 500;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
  transition: opacity 300ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    .price-table__action {
      transition: none; } }
  .price-table__action:hover, .price-table__action:focus {
    opacity: 0.9;
    color: #FFFFFF; }

.invoice {
  min-width: 1100px;
  max-width: 1170px;
  border-radius: 2px;
  padding: 2.5rem;
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075); }

.invoice__header {
  padding: 1.5rem;
  text-align: center;
  border-radius: 2px 2px 0 0;
  margin-bottom: 1.5rem; }

.invoice__address {
  margin-bottom: 4rem; }
  .invoice__address h4 {
    font-weight: normal;
    margin-bottom: 1rem; }

.invoice__attrs {
  margin-bottom: 2.5rem; }
  .invoice__attrs [class*="col-"] {
    padding: -10px; }

.invoice__attrs__item {
  padding: 1.5rem 2rem;
  border-radius: 2px;
  text-align: center;
  border: 1px solid #f6f6f6; }
  .invoice__attrs__item small {
    margin-bottom: 0.2rem;
    display: block;
    font-size: 1rem; }
  .invoice__attrs__item h3 {
    margin: 0;
    line-height: 100%;
    font-weight: normal; }

.invoice__table {
  margin-bottom: 4rem; }

.invoice__footer {
  text-align: center;
  margin: 4rem 0 1.5rem; }
  .invoice__footer > a {
    color: #747a80; }

@media print {
  @page {
    margin: 0;
    size: auto; }
  body {
    margin: 0mm 0mm 0mm 0mm !important;
    padding: 0mm !important; }
  .header,
  .actions,
  .content__title,
  .footer,
  .notifications,
  .navigation,
  .chat,
  .growl-animated,
  .btn--action {
    display: none !important; }
  .invoice {
    padding: 30px !important;
    -webkit-print-color-adjust: exact !important; } }

.login {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 1.2rem; }

.login__block {
  max-width: 330px;
  width: 100%;
  display: none;
  text-align: center;
  padding: 1.2rem;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075); }
  @media (min-width: 576px) {
    .login__block:hover .login__block__actions .dropdown {
      display: block; } }

.login__block.active {
  z-index: 10;
  display: inline-block; }

.login__block__header {
  padding: 1.5rem;
  margin-top: -2.4rem;
  position: relative;
  color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.18); }
  .login__block__header > i,
  .login__block__header > img {
    display: block;
    margin-bottom: 0.8rem; }
  .login__block__header > i {
    font-size: 3rem; }
  .login__block__header > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.33); }

.login__block__actions {
  position: absolute;
  bottom: 1rem;
  right: 1rem; }
  .login__block__actions .dropdown:not(.show) {
    display: none; }

.login__block__body {
  padding: 1rem; }

.login__block__btn {
  margin-top: 0.5rem; }
  .login__block__btn, .login__block__btn:hover, .login__block__btn:focus {
    color: #FFFFFF; }
  .login__block__btn:hover {
    opacity: 0.9; }

.todo__item {
  padding-left: 60px;
  display: block;
  position: relative; }
  .todo__item > .checkbox__char {
    position: absolute;
    left: 0;
    top: 0; }
  .todo__item small {
    display: block;
    font-size: 0.95rem;
    margin-top: 0.2rem; }
  .todo__item > input[type=checkbox]:checked ~ .listview__content,
  .todo__item > input[type=checkbox]:checked ~ .listview__attrs {
    text-decoration: line-through; }

.notes__item {
  margin-bottom: 30px; }
  .notes__item > a {
    height: 155px;
    background-color: #FFFFFF;
    display: block;
    padding: 1.8rem 2rem;
    position: relative;
    color: #747a80;
    transition: background-color 300ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .notes__item > a {
        transition: none; } }
    .notes__item > a, .notes__item > a:before {
      border-radius: 2px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08); }
    .notes__item > a:before {
      content: '';
      position: absolute;
      width: calc(100% - 10px);
      bottom: -5px;
      left: 5px;
      z-index: -1;
      height: 20px;
      background-color: #FFFFFF;
      transition: bottom 200ms; }
      @media screen and (prefers-reduced-motion: reduce) {
        .notes__item > a:before {
          transition: none; } }
  .notes__item:hover > a:before {
    bottom: -8px; }
  .notes__item:hover .notes__actions {
    opacity: 1; }

.notes__title {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.notes__actions {
  position: absolute;
  right: 2.2rem;
  bottom: 1rem;
  font-size: 1.1rem;
  width: 2.2rem;
  height: 2.2rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  line-height: 2.2rem;
  color: #FFFFFF;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 300ms;
  cursor: pointer; }
  @media screen and (prefers-reduced-motion: reduce) {
    .notes__actions {
      transition: none; } }
  .notes__actions:hover {
    background: rgba(0, 0, 0, 0.9); }

.note-view .trumbowyg-box {
  border: 0; }

.note-view__field {
  border-bottom: 1px solid #eceff1; }
  .note-view__field input {
    border: 0;
    font-size: 1rem;
    padding: 1.7rem 2rem;
    height: auto; }

.note-view__field--color {
  padding: 1.2rem 2rem 0.8rem; }

.note-view__label {
  float: left;
  margin: 0.4rem 1.5rem 0 0; }

[data-ma-theme="red"] .header,
[data-ma-theme="red"] .login__block__header,
[data-ma-theme="red"] .login__block__btn,
[data-ma-theme="red"] .top-menu {
  background-color: #ff6b68; }

[data-ma-theme="red"] .navigation__sub--active > a,
[data-ma-theme="red"] .navigation__active > a,
[data-ma-theme="red"] .navigation__active:before {
  color: #ff6b68; }

[data-ma-theme="purple"] .header,
[data-ma-theme="purple"] .login__block__header,
[data-ma-theme="purple"] .login__block__btn,
[data-ma-theme="purple"] .top-menu {
  background-color: #d066e2; }

[data-ma-theme="purple"] .navigation__sub--active > a,
[data-ma-theme="purple"] .navigation__active > a,
[data-ma-theme="purple"] .navigation__active:before {
  color: #d066e2; }

[data-ma-theme="indigo"] .header,
[data-ma-theme="indigo"] .login__block__header,
[data-ma-theme="indigo"] .login__block__btn,
[data-ma-theme="indigo"] .top-menu {
  background-color: #3F51B5; }

[data-ma-theme="indigo"] .navigation__sub--active > a,
[data-ma-theme="indigo"] .navigation__active > a,
[data-ma-theme="indigo"] .navigation__active:before {
  color: #3F51B5; }

[data-ma-theme="blue"] .header,
[data-ma-theme="blue"] .login__block__header,
[data-ma-theme="blue"] .login__block__btn,
[data-ma-theme="blue"] .top-menu {
  background-color: #2196F3; }

[data-ma-theme="blue"] .navigation__sub--active > a,
[data-ma-theme="blue"] .navigation__active > a,
[data-ma-theme="blue"] .navigation__active:before {
  color: #2196F3; }

[data-ma-theme="cyan"] .header,
[data-ma-theme="cyan"] .login__block__header,
[data-ma-theme="cyan"] .login__block__btn,
[data-ma-theme="cyan"] .top-menu {
  background-color: #00BCD4; }

[data-ma-theme="cyan"] .navigation__sub--active > a,
[data-ma-theme="cyan"] .navigation__active > a,
[data-ma-theme="cyan"] .navigation__active:before {
  color: #00BCD4; }

[data-ma-theme="teal"] .header,
[data-ma-theme="teal"] .login__block__header,
[data-ma-theme="teal"] .login__block__btn,
[data-ma-theme="teal"] .top-menu {
  background-color: #39bbb0; }

[data-ma-theme="teal"] .navigation__sub--active > a,
[data-ma-theme="teal"] .navigation__active > a,
[data-ma-theme="teal"] .navigation__active:before {
  color: #39bbb0; }

[data-ma-theme="green"] .header,
[data-ma-theme="green"] .login__block__header,
[data-ma-theme="green"] .login__block__btn,
[data-ma-theme="green"] .top-menu {
  background-color: #32c787; }

[data-ma-theme="green"] .navigation__sub--active > a,
[data-ma-theme="green"] .navigation__active > a,
[data-ma-theme="green"] .navigation__active:before {
  color: #32c787; }

[data-ma-theme="brown"] .header,
[data-ma-theme="brown"] .login__block__header,
[data-ma-theme="brown"] .login__block__btn,
[data-ma-theme="brown"] .top-menu {
  background-color: #795548; }

[data-ma-theme="brown"] .navigation__sub--active > a,
[data-ma-theme="brown"] .navigation__active > a,
[data-ma-theme="brown"] .navigation__active:before {
  color: #795548; }

[data-ma-theme="orange"] .header,
[data-ma-theme="orange"] .login__block__header,
[data-ma-theme="orange"] .login__block__btn,
[data-ma-theme="orange"] .top-menu {
  background-color: #FF9800; }

[data-ma-theme="orange"] .navigation__sub--active > a,
[data-ma-theme="orange"] .navigation__active > a,
[data-ma-theme="orange"] .navigation__active:before {
  color: #FF9800; }

[data-ma-theme="blue-grey"] .header,
[data-ma-theme="blue-grey"] .login__block__header,
[data-ma-theme="blue-grey"] .login__block__btn,
[data-ma-theme="blue-grey"] .top-menu {
  background-color: #607D8B; }

[data-ma-theme="blue-grey"] .navigation__sub--active > a,
[data-ma-theme="blue-grey"] .navigation__active > a,
[data-ma-theme="blue-grey"] .navigation__active:before {
  color: #607D8B; }

.theme-switch .btn-group--colors {
  display: block;
  margin-top: 0.75rem; }

.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  z-index: 1000000;
  text-align: center;
  padding: 3rem;
  overflow: auto; }
  .ie-warning > h1 {
    font-size: 2rem; }
  .ie-warning p {
    font-size: 1.2rem;
    color: #9c9c9c; }

.ie-warning__downloads {
  background-color: #f6f6f6;
  padding: 30px 0;
  margin: 30px 0; }
  .ie-warning__downloads > a {
    padding: 0 10px; }

.error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100vh;
  width: 100%; }

.error__inner {
  max-width: 600px;
  width: 100%;
  padding: 1rem;
  text-align: center; }
  .error__inner > h1 {
    font-size: 8rem;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
    line-height: 100%;
    margin-bottom: 1.5rem; }
  .error__inner > h2 {
    font-weight: normal;
    margin: 1.3rem 0;
    font-size: 1.5rem; }

.results__header {
  padding: 2rem 2rem 0;
  border-radius: 2px 2px 0 0;
  margin-bottom: 2rem;
  background-color: #f9f9f9; }

.results__search {
  position: relative; }
  .results__search input[type="text"] {
    width: 100%;
    border: 0;
    border-radius: 2px;
    background-color: #FFFFFF;
    color: #495057;
    padding: 0 1rem 0 3rem;
    height: 2.9rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
    transition: box-shadow 300ms; }
    .results__search input[type="text"]::-webkit-input-placeholder {
      color: #868e96; }
    .results__search input[type="text"]:-moz-placeholder {
      color: #868e96; }
    .results__search input[type="text"]::-moz-placeholder {
      color: #868e96; }
    .results__search input[type="text"]:-ms-input-placeholder {
      color: #868e96; }
    .results__search input[type="text"]:focus {
      box-shadow: 0 7px 12px rgba(0, 0, 0, 0.125); }
  .results__search:before {
    content: '';
    font-family: "Material-Design-Iconic-Font";
    content: "";
    font-size: 1.3rem;
    position: absolute;
    top: 0.55rem;
    left: 1.1rem;
    z-index: 1; }

.results__nav {
  border: 0; }

.issue-tracker .listview__item {
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 767.98px) {
    .issue-tracker .listview__item {
      display: block; } }

.issue-tracker__item:not(.actions) {
  margin-left: 2rem; }

.issue-tracker__item > .zmdi {
  font-size: 1.15rem;
  vertical-align: top;
  position: relative;
  top: 0.25rem;
  margin-right: 0.5rem; }

.issue-tracker__item.actions {
  margin: 0 -1rem 0 1rem; }

.issue-tracker__tag {
  padding: 0.3rem 0.75rem 0.4rem;
  line-height: 100%;
  font-size: 0.95rem;
  border-radius: 2px;
  color: #FFFFFF; }

.team {
  margin-top: 7rem; }

.team__item {
  text-align: center;
  margin-bottom: 7rem; }
  @media (max-width: 767.98px) {
    .team__item {
      max-width: 365px;
      margin: 0 auto 80px; } }
  .team__item .card-subtitle {
    margin-bottom: 1rem; }

.team__img {
  display: inline-block;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 7px solid #FFFFFF;
  margin: -4rem auto -0.5rem; }

.team__social {
  margin-top: 2rem; }
  .team__social > a {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 36px;
    color: #FFFFFF;
    background-color: #f9f9f9;
    font-size: 1.2rem;
    margin: 0 1px;
    transition: opacity 300ms, background-color 300ms; }
    .team__social > a:hover {
      opacity: 0.9; }

.blog__tags {
  text-align: center;
  background-color: #f9f9f9;
  padding: 2rem 1rem 1.5rem;
  margin: 2rem 0 0.5rem; }

.blog__arthur {
  padding: 2rem 2rem 2.5rem;
  text-align: center; }

.blog__arthur-img {
  margin-bottom: 1.5rem; }
  .blog__arthur-img > img {
    width: 100px;
    height: 100px;
    border-radius: 50%; }

.blog__arthur-social {
  margin: 2rem 0 0 0; }
  .blog__arthur-social > a {
    display: inline-block;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 50%;
    line-height: 36px;
    color: #FFFFFF;
    font-size: 1.2rem;
    margin: 0 1px;
    transition: opacity 300ms, background-color 300ms; }
    .blog__arthur-social > a:hover {
      opacity: 0.9; }

.q-a__item {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }

.q-a__stat {
  margin: 0.35rem 2rem 0 0;
  -ms-flex-item-align: start;
      align-self: flex-start; }
  .q-a__stat > span {
    display: inline-block;
    width: 70px;
    border-radius: 2px;
    background-color: #f6f6f6;
    text-align: center;
    padding: 0.9rem 0.5rem 0.65rem;
    margin-right: 0.2rem; }
    .q-a__stat > span > strong {
      display: block;
      font-size: 1.2rem;
      font-weight: normal;
      line-height: 100%;
      color: #333;
      margin: 0.1rem 0 0.075rem; }
    .q-a__stat > span > small {
      text-transform: uppercase;
      line-height: 100%; }

.q-a__question {
  position: relative;
  margin-bottom: 3rem; }
  @media (min-width: 768px) {
    .q-a__question {
      margin-top: 2rem; } }
  @media (min-width: 576px) {
    .q-a__question {
      padding-left: 100px; } }
  .q-a__question > h2 {
    font-size: 1.25rem;
    font-weight: normal; }
    .q-a__question > h2 + p {
      margin-top: 1rem; }

.q-a__vote {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center; }
  .q-a__vote > i {
    font-size: 1.25rem;
    cursor: pointer; }

.q-a__vote__votes {
  padding: .5rem 0;
  background-color: #FFFFFF;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  width: 75px;
  font-size: 1.2rem;
  margin-bottom: 0.35rem;
  color: #333;
  font-weight: normal; }

.q-a__info {
  margin-top: 1.5rem;
  padding: 1.25rem 0;
  border-top: 1px solid #e9ecef;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .q-a__info .actions {
    margin: 0 -0.5rem 0 auto; }

.q-a__op > a > img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 0.5rem; }

.q-a__vote-answer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.widget-past-days {
  background-color: #00BCD4;
  overflow: hidden; }

.widget-past-days__main {
  margin: 0 -10px; }

.widget-past-days__chart {
  opacity: 0.75;
  margin: 0.55rem 0 0 auto; }

.widget-past-days__info small {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9); }

.widget-past-days__info h3 {
  margin: 0;
  color: #FFFFFF;
  font-weight: normal; }

.widget-visitors__stats {
  margin: 0 -0.5rem 2rem; }
  .widget-visitors__stats::after {
    display: block;
    clear: both;
    content: ""; }
  .widget-visitors__stats > div {
    border: 1px solid #f6f6f6;
    padding: 1.2rem 1.5rem 1.1rem;
    float: left;
    margin: 0 0.5rem;
    width: calc(50% - 1rem); }
    .widget-visitors__stats > div > strong {
      font-size: 1.3rem;
      font-weight: normal;
      line-height: 100%;
      color: #333; }
    .widget-visitors__stats > div > small {
      display: block;
      color: #9c9c9c;
      font-size: 1rem;
      line-height: 100%;
      margin-top: 0.45rem; }

.widget-visitors__map {
  width: 100%;
  height: 250px; }

.widget-visitors__country {
  height: 1rem;
  width: 1.5rem;
  vertical-align: top;
  position: relative;
  margin-right: 0.25rem;
  left: -0.1rem;
  border-radius: 1px; }

.widget-pie {
  background-color: #ff6b68;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .widget-pie::after {
    display: block;
    clear: both;
    content: ""; }

.widget-pie__item {
  padding: 20px 0;
  text-align: center; }
  .widget-pie__item:nth-child(2n) {
    background-color: rgba(255, 255, 255, 0.1); }

.widget-pie__title {
  color: #FFFFFF; }

.quick-stats__item {
  padding: 1.5rem 1.5rem 1.45rem;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }
  .quick-stats__item::after {
    display: block;
    clear: both;
    content: ""; }

.quick-stats__chart,
.quick-stats__info {
  display: inline-block;
  vertical-align: middle; }

.quick-stats__info {
  min-width: 0; }
  .quick-stats__info > h2,
  .quick-stats__info > small {
    line-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .quick-stats__info > h2 {
    font-weight: normal;
    margin: 0;
    font-size: 1.3rem;
    color: #FFFFFF; }
  .quick-stats__info > small {
    font-size: 1rem;
    display: block;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 0.6rem; }

.quick-stats__chart {
  margin-left: auto;
  padding-left: 1.2rem; }
  @media (min-width: 576px) and (max-width: 1199.98px) {
    .quick-stats__chart {
      display: none; } }

.stats {
  padding-top: 1rem; }

.stats__item {
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  margin-bottom: 30px;
  padding: 1rem; }

.stats__chart {
  border-radius: 2px;
  padding-top: 2rem;
  margin-top: -2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden; }
  .stats__chart .flot-chart {
    margin: 0 -12px -12px; }

.stats__info {
  padding: 1.8rem 1rem 0.5rem;
  position: relative;
  text-align: center; }
  .stats__info h2 {
    font-size: 1.3rem;
    margin: 0; }
  .stats__info small {
    display: block;
    font-size: 1rem;
    margin-top: 0.4rem;
    color: #9c9c9c; }

.widget-pictures__body {
  margin: 0;
  padding: 2px;
  text-align: center; }
  .widget-pictures__body::after {
    display: block;
    clear: both;
    content: ""; }
  .widget-pictures__body > a {
    padding: 2px;
    display: block; }
    .widget-pictures__body > a img {
      width: 100%;
      border-radius: 2px; }
    .widget-pictures__body > a:hover {
      opacity: 0.9; }

.widget-ratings__star {
  font-size: 1.5rem;
  color: #e9ecef;
  margin: 0.5rem 0 0; }
  .widget-ratings__star .active {
    color: #ffc721; }

.widget-ratings__item {
  padding: 0.5rem 0; }
  .widget-ratings__item::after {
    display: block;
    clear: both;
    content: ""; }
  .widget-ratings__item .float-left,
  .widget-ratings__item .float-right {
    font-size: 1.15rem; }
  .widget-ratings__item .float-left .zmdi {
    font-size: 1.5rem;
    vertical-align: top;
    color: #ffc721;
    position: relative;
    top: 0.15rem;
    margin-left: 0.35rem; }
  .widget-ratings__item:last-child {
    padding-bottom: 0; }

.widget-ratings__progress {
  overflow: hidden;
  padding: 0.6rem 1.5rem; }

.widget-profile {
  background-color: #03A9F4; }
  .widget-profile .avatar-char {
    background-color: rgba(255, 255, 255, 0.25);
    color: #FFFFFF;
    margin-right: 1.2rem; }

.widget-profile__img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 1.2rem;
  border: 5px solid rgba(255, 255, 255, 0.1); }

.widget-profile__list {
  color: #FFFFFF; }
  .widget-profile__list .media {
    padding: 1rem 2rem; }
    .widget-profile__list .media:nth-child(odd) {
      background-color: rgba(255, 255, 255, 0.1); }
  .widget-profile__list .media-body strong {
    display: block;
    font-weight: 500; }
  .widget-profile__list .media-body small {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.92rem; }

.widget-contacts__map {
  display: block;
  padding: 3px 3px 4px; }
  .widget-contacts__map img {
    width: 100%;
    border-radius: 2px;
    margin: -20px 0 -1px; }

.widget-signups {
  background-color: #607D8B; }

.widget-signups__list {
  text-align: center; }
  .widget-signups__list > a {
    vertical-align: top;
    margin: 4px 2px;
    display: inline-block; }
  .widget-signups__list .avatar-char {
    background-color: rgba(255, 255, 255, 0.1);
    color: #FFFFFF; }
  .widget-signups__list .avatar-char,
  .widget-signups__list .avatar-img {
    margin: 0; }

.widget-time {
  padding: 2rem;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  margin-bottom: 30px; }
  .widget-time .time {
    font-size: 2rem;
    text-align: center; }
    .widget-time .time > span {
      padding: 1rem 1.5rem;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 2px;
      display: inline-block;
      margin: 0 0.25rem;
      position: relative;
      color: #FFFFFF; }
      .widget-time .time > span:after {
        position: absolute;
        right: -13px;
        top: 10px; }

.widget-search {
  border-radius: 2px;
  margin-bottom: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  position: relative; }
  .widget-search > i {
    top: 1.3rem;
    left: 1.5rem;
    position: absolute;
    font-size: 1.5rem; }
  .widget-search:not(.widget-search__inverse) {
    background-color: #FFFFFF; }

.widget-search--inverse {
  color: #FFFFFF; }
  .widget-search--inverse .widget-search__input {
    color: #FFFFFF; }
    .widget-search--inverse .widget-search__input::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.75); }
    .widget-search--inverse .widget-search__input:-moz-placeholder {
      color: rgba(255, 255, 255, 0.75); }
    .widget-search--inverse .widget-search__input::-moz-placeholder {
      color: rgba(255, 255, 255, 0.75); }
    .widget-search--inverse .widget-search__input:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.75); }

.widget-search__input {
  border: 0;
  background-color: transparent;
  padding: 0 2rem 0 3.75rem;
  width: 100%;
  height: 4rem;
  font-size: 1.15rem; }

.flot-chart {
  height: 200px;
  display: block; }

.flot-chart--sm {
  height: 100px; }

.flot-chart--xs {
  height: 70px; }

.flot-chart-legends {
  text-align: center;
  margin: 20px 0 -10px; }
  .flot-chart-legends table {
    display: inline-block; }
  .flot-chart-legends .legendColorBox > div > div {
    border-radius: 50%; }
  .flot-chart-legends .legendLabel {
    padding: 0 8px 0 3px; }

.flot-tooltip {
  position: absolute;
  line-height: 100%;
  color: #747a80;
  display: none;
  font-size: 0.95rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 0.7rem 1rem;
  background-color: #FFFFFF;
  z-index: 99999; }

#jqstooltip {
  text-align: center;
  padding: 5px 10px;
  border: 0;
  height: auto !important;
  width: auto !important;
  background: #FFFFFF;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px; }
  #jqstooltip .jqsfield {
    font-size: 0.95rem;
    font-weight: 500;
    font-family: inherit;
    text-align: center;
    color: #747a80; }
    #jqstooltip .jqsfield > span {
      display: none; }

.select2-container--default .select2-selection--single {
  border-radius: 0;
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #eceff1;
  height: auto; }
  .select2-container--default .select2-selection--single:before {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: 1;
    right: 0;
    bottom: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 8px 8px;
    border-color: transparent transparent #d1d1d1 transparent; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    display: none; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #868e96; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 1.5;
    padding: 0.375rem 2px; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 0; }

.select2-container--default .select2-selection--multiple {
  background-color: transparent;
  border: 0;
  box-shadow: 0 1px 0 0 #eceff1;
  border-radius: 0;
  padding-bottom: 1px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    border-radius: 2px;
    border: 0;
    background-color: #f5f6f8;
    padding: 0.4rem 0.8rem;
    color: #495057; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    margin-right: 0.5rem; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: transparent;
  opacity: 0.5; }

.select2-container--default .selection {
  position: relative;
  display: block; }
  .select2-container--default .selection:before, .select2-container--default .selection:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    bottom: 0;
    transition: all 200ms;
    transition-timing-function: ease;
    background-color: #2196F3; }
    @media screen and (prefers-reduced-motion: reduce) {
      .select2-container--default .selection:before, .select2-container--default .selection:after {
        transition: none; } }
  .select2-container--default .selection:before {
    left: 50%; }
  .select2-container--default .selection:after {
    right: 50%; }

.select2-container--open .selection:before, .select2-container--open .selection:after {
  width: 50%; }

.select2-dropdown {
  background-color: #FFFFFF;
  border: 0;
  margin-top: 1px;
  border-radius: 2px;
  padding: 0.8rem 0;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11);
  z-index: 19;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
  .select2-dropdown .select2-results__option {
    padding: 0.65rem 1.5rem;
    transition: background-color 300ms, color 300ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .select2-dropdown .select2-results__option {
        transition: none; } }
  .select2-dropdown .select2-results__option--highlighted[aria-selected] {
    background-color: #f9f9f9;
    color: #16181b; }
  .select2-dropdown .select2-results__option[aria-selected=true] {
    position: relative;
    padding-right: 1.5rem;
    background-color: #f9f9f9;
    color: #16181b; }
    .select2-dropdown .select2-results__option[aria-selected=true]:before {
      font-family: "Material-Design-Iconic-Font";
      content: '\f26b';
      position: absolute;
      top: 0.45rem;
      right: 1.5rem;
      font-size: 1.3rem;
      color: #16181b; }
  .select2-dropdown .select2-search--dropdown {
    margin-top: -0.5rem;
    border-bottom: 1px solid #eceff1;
    position: relative;
    margin-bottom: 1rem; }
    .select2-dropdown .select2-search--dropdown:before {
      font-family: "Material-Design-Iconic-Font";
      content: '\f1c3';
      font-size: 1.5rem;
      color: #747a80;
      position: absolute;
      left: 1.4rem;
      top: 0.65rem; }
    .select2-dropdown .select2-search--dropdown .select2-search__field {
      border: 0;
      background-color: transparent;
      height: 2.8rem;
      color: #495057;
      padding-left: 3.5rem; }

.dropzone {
  border: 0;
  background-color: #f6f6f6;
  border-radius: 2px;
  transition: border-color 300ms, background-color 300ms;
  min-height: 50px;
  position: relative; }
  @media screen and (prefers-reduced-motion: reduce) {
    .dropzone {
      transition: none; } }
  .dropzone:before {
    font-family: "Material-Design-Iconic-Font";
    content: '\f22a';
    font-size: 2rem;
    color: #747a80;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 300ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .dropzone:before {
        transition: none; } }
  .dropzone .dz-preview.dz-file-preview .dz-image, .dropzone .dz-preview.dz-image-preview .dz-image {
    border-radius: 2px;
    border: 3px solid #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); }
  .dropzone .dz-preview .dz-remove {
    position: absolute;
    top: -3px;
    right: -4px;
    z-index: 20;
    font-size: 0;
    width: 22px;
    height: 22px;
    background-color: #ff6b68;
    border-radius: 50%;
    border: 2px solid #FFFFFF; }
    .dropzone .dz-preview .dz-remove:hover {
      background-color: #ff524f;
      text-decoration: none; }
    .dropzone .dz-preview .dz-remove:before {
      content: '\f136';
      font-size: 0.8rem;
      font-family: "Material-Design-Iconic-Font";
      color: #FFFFFF;
      font-weight: bold;
      line-height: 19px;
      padding: 0 6px; }
  .dropzone .dz-message {
    transition: opacity 300ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .dropzone .dz-message {
        transition: none; } }
    .dropzone .dz-message span {
      font-size: 1rem;
      color: #9ca0a5;
      display: inline-block;
      border-radius: 2px;
      transition: color 300ms, box-shadow, 300ms;
      padding: 0.5rem 1.4rem 0.8rem;
      background-color: #FFFFFF;
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1); }
      @media screen and (prefers-reduced-motion: reduce) {
        .dropzone .dz-message span {
          transition: none; } }
      .dropzone .dz-message span:before {
        content: '\f21e';
        font-family: "Material-Design-Iconic-Font";
        font-size: 1.6rem;
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 0.8rem; }
  .dropzone:hover .dz-message span {
    color: #747a80; }
  .dropzone:hover, .dropzone.dz-drag-hover {
    background-color: #eeeeee; }
  .dropzone.dz-drag-hover .dz-message {
    opacity: 0; }
  .dropzone.dz-drag-hover:before {
    opacity: 1; }

.noUi-target {
  border-radius: 0;
  box-shadow: none;
  border: 0;
  background: #f6f6f6;
  margin: 15px 0; }

.noUi-horizontal {
  height: 2px; }

.noUi-vertical {
  width: 3px; }

.noUi-connect {
  background: #39bbb0;
  box-shadow: none; }

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  cursor: pointer;
  border: 0;
  box-shadow: none;
  background-color: #39bbb0;
  transition: box-shadow 200ms, -webkit-transform 200ms;
  transition: transform 200ms, box-shadow 200ms;
  transition: transform 200ms, box-shadow 200ms, -webkit-transform 200ms; }
  .noUi-horizontal .noUi-handle.noUi-active,
  .noUi-vertical .noUi-handle.noUi-active {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.04); }
  .noUi-horizontal .noUi-handle:before, .noUi-horizontal .noUi-handle:after,
  .noUi-vertical .noUi-handle:before,
  .noUi-vertical .noUi-handle:after {
    display: none;
    border: 0; }

.noUi-horizontal .noUi-handle {
  right: -6px !important;
  top: -5px; }

.noUi-vertical .noUi-handle {
  left: -4px;
  top: -6px; }

.input-slider--blue .noUi-connect {
  background: #2196F3; }

.input-slider--blue.noUi-horizontal .noUi-handle, .input-slider--blue.noUi-vertical .noUi-handle {
  background-color: #2196F3; }

.input-slider--red .noUi-connect {
  background: #ff6b68; }

.input-slider--red.noUi-horizontal .noUi-handle, .input-slider--red.noUi-vertical .noUi-handle {
  background-color: #ff6b68; }

.input-slider--amber .noUi-connect {
  background: #FF9800; }

.input-slider--amber.noUi-horizontal .noUi-handle, .input-slider--amber.noUi-vertical .noUi-handle {
  background-color: #FF9800; }

.input-slider--green .noUi-connect {
  background: #32c787; }

.input-slider--green.noUi-horizontal .noUi-handle, .input-slider--green.noUi-vertical .noUi-handle {
  background-color: #32c787; }

.easy-pie-chart {
  display: inline-block;
  position: relative; }

.easy-pie-chart__value {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100%; }
  .easy-pie-chart__value:after {
    content: "%";
    font-size: 12px; }

.easy-pie-chart__title {
  margin-top: -2px;
  line-height: 15px;
  font-size: 11px; }

.dataTables_wrapper {
  margin-top: 20px; }
  .dataTables_wrapper .table {
    margin: 40px 0 20px; }
    .dataTables_wrapper .table > thead > tr > th {
      cursor: pointer; }
      .dataTables_wrapper .table > thead > tr > th:hover {
        background-color: #fbfbfb; }
      .dataTables_wrapper .table > thead > tr > th.sorting_asc, .dataTables_wrapper .table > thead > tr > th.sorting_desc {
        position: relative; }
        .dataTables_wrapper .table > thead > tr > th.sorting_asc:after, .dataTables_wrapper .table > thead > tr > th.sorting_desc:after {
          font-family: "Material-Design-Iconic-Font";
          position: absolute;
          top: 0.75rem;
          right: 1rem;
          font-size: 1.4rem; }
      .dataTables_wrapper .table > thead > tr > th.sorting_asc:after {
        content: '\f1cd'; }
      .dataTables_wrapper .table > thead > tr > th.sorting_desc:after {
        content: '\f1ce'; }

.dataTables_length,
.dataTables_filter {
  font-size: 0;
  position: relative; }
  .dataTables_length:after,
  .dataTables_filter:after {
    font-family: "Material-Design-Iconic-Font";
    position: absolute;
    left: 1px;
    bottom: 5px;
    font-size: 1.2rem;
    color: #333; }
  .dataTables_length > label,
  .dataTables_filter > label {
    margin: 0;
    width: 100%; }
    .dataTables_length > label select,
    .dataTables_length > label input[type=search],
    .dataTables_filter > label select,
    .dataTables_filter > label input[type=search] {
      padding-left: 25px;
      font-size: 1rem;
      background: none;
      border: 0;
      height: 35px;
      border-radius: 0;
      width: 100%;
      border-bottom: 1px solid #eceff1; }

.dataTables_length {
  float: right;
  margin-left: 20px; }
  .dataTables_length:before {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: 1;
    right: 0;
    bottom: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 8px 8px;
    border-color: transparent transparent #d1d1d1 transparent; }
  @media (min-width: 576px) {
    .dataTables_length {
      min-width: 150px; } }
  @media (max-width: 575.98px) {
    .dataTables_length {
      display: none; } }
  .dataTables_length:after {
    content: '\f197'; }
  .dataTables_length select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }

.dataTables_filter {
  overflow: hidden; }
  .dataTables_filter:after {
    content: '\f1c3'; }
  .dataTables_filter > label:before, .dataTables_filter > label:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    bottom: 0;
    transition: width 500ms ease;
    background-color: #2196F3; }
  .dataTables_filter > label:before {
    left: 50%; }
  .dataTables_filter > label:after {
    right: 50%; }

.dataTables_filter--toggled > label:before, .dataTables_filter--toggled > label:after {
  width: 50%; }

.dataTables_paginate {
  text-align: center; }

.paginate_button {
  background-color: #f3f3f3;
  display: inline-block;
  color: #8e9499;
  vertical-align: top;
  border-radius: 50%;
  margin: 0 1px 0 2px;
  font-size: 1rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  transition: background-color 300ms, color 300ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    .paginate_button {
      transition: none; } }
  .paginate_button.current {
    background-color: #03A9F4;
    color: #FFFFFF;
    cursor: default; }
  .paginate_button:not(.current):not(.disabled):hover, .paginate_button:not(.current):not(.disabled):focus {
    background-color: #e6e6e6;
    color: #5c6165; }
  .paginate_button.current, .paginate_button.disabled {
    cursor: default; }
  .paginate_button.previous, .paginate_button.next {
    font-size: 0;
    position: relative; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .paginate_button.previous, .paginate_button.next {
        font-size: 1rem; } }
    .paginate_button.previous:before, .paginate_button.next:before {
      font-family: "Material-Design-Iconic-Font";
      font-size: 1rem;
      line-height: 2.55rem; }
  .paginate_button.previous:before {
    content: '\F2FF'; }
  .paginate_button.next:before {
    content: '\F301'; }
  .paginate_button.disabled {
    opacity: 0.6; }
    .paginate_button.disabled:hover, .paginate_button.disabled:focus {
      color: #8e9499; }

.dataTables_info {
  text-align: center;
  margin-bottom: 25px;
  font-size: 0.9rem;
  color: #9c9c9c; }

.dataTables_buttons {
  float: right;
  margin: 0 0 0 20px;
  border-bottom: 1px solid #eceff1;
  min-height: 35px; }

.dt-buttons {
  display: none; }

.data-table-toggled {
  overflow: hidden; }
  .data-table-toggled .dataTables_buttons [data-table-action="fullscreen"]:before {
    content: '\f16c'; }

.flatpickr-calendar {
  border-radius: 2px;
  border: 0;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11);
  width: auto;
  margin-top: -2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .flatpickr-calendar:before, .flatpickr-calendar:after {
    display: none; }

.flatpickr-months {
  background-color: #39bbb0;
  border-radius: 2px 2px 0 0; }
  .flatpickr-months .flatpickr-month {
    height: 60px; }
  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    width: 35px;
    height: 35px;
    padding: 0;
    line-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 50%;
    color: #FFFFFF;
    top: 13px;
    transition: background-color 300ms; }
    .flatpickr-months .flatpickr-prev-month:hover,
    .flatpickr-months .flatpickr-next-month:hover {
      color: #FFFFFF;
      background-color: rgba(255, 255, 255, 0.2); }
  .flatpickr-months .flatpickr-prev-month {
    margin-left: 15px; }
  .flatpickr-months .flatpickr-next-month {
    margin-right: 15px; }

.flatpickr-current-month {
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-top: 18px; }
  .flatpickr-current-month span.cur-month,
  .flatpickr-current-month input.cur-year {
    font-weight: 500; }
  .flatpickr-current-month span.cur-month:hover {
    background-color: transparent; }
  .flatpickr-current-month .numInputWrapper:hover {
    background-color: transparent; }
  .flatpickr-current-month .numInputWrapper span {
    border: 0;
    right: -5px;
    padding: 0; }
    .flatpickr-current-month .numInputWrapper span:after {
      left: 3px; }
    .flatpickr-current-month .numInputWrapper span.arrowUp:after {
      border-bottom-color: #FFFFFF; }
    .flatpickr-current-month .numInputWrapper span.arrowDown:after {
      border-top-color: #FFFFFF; }

span.flatpickr-weekday {
  font-weight: 400;
  color: #333; }

.flatpickr-day {
  font-size: 0.92rem;
  border: 0;
  color: #747a80; }
  .flatpickr-day.selected, .flatpickr-day.selected:hover {
    background-color: #39bbb0 !important;
    color: #FFFFFF !important; }
  .flatpickr-day.today, .flatpickr-day.today:hover {
    background-color: #f6f6f6;
    color: #333; }
  .flatpickr-day:hover {
    background-color: #f6f6f6; }

.numInputWrapper span:hover {
  background-color: #FFFFFF; }

.flatpickr-time {
  border: 0 !important;
  background-color: #f9f9f9; }
  .flatpickr-time .numInputWrapper:hover,
  .flatpickr-time .flatpickr-am-pm:hover {
    background-color: #f9f9f9; }

.flatpickr-innerContainer {
  padding: 15px; }

.colorpicker {
  padding: 5px;
  margin-top: 1px; }
  .colorpicker:before, .colorpicker:after {
    display: none; }
  .colorpicker div {
    border-radius: 2px; }
  .colorpicker.colorpicker-horizontal {
    width: 160px; }
    .colorpicker.colorpicker-horizontal .colorpicker-hue,
    .colorpicker.colorpicker-horizontal .colorpicker-alpha,
    .colorpicker.colorpicker-horizontal .colorpicker-color {
      width: 100%; }

.colorpicker-saturation {
  width: 150px;
  height: 150px; }
  .colorpicker-saturation i {
    border: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.36); }
    .colorpicker-saturation i, .colorpicker-saturation i b {
      height: 10px;
      width: 10px; }

.colorpicker-hue,
.colorpicker-alpha {
  width: 20px;
  height: 150px; }

.colorpicker-color, .colorpicker-color div {
  height: 20px; }

.color-picker__preview {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  border-radius: 50%; }

.trumbowyg-box,
.trumbowyg-editor {
  border-color: #eceff1;
  margin: 0; }

.trumbowyg-button-pane {
  background-color: #FFFFFF;
  border-color: #eceff1; }
  .trumbowyg-button-pane:after {
    display: none; }
  .trumbowyg-button-pane .trumbowyg-button-group:after {
    display: none; }
  .trumbowyg-button-pane button {
    margin: 0; }

.trumbowyg-dropdown {
  border: 0;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11); }
  .trumbowyg-dropdown button {
    font-size: 1rem;
    height: 40px;
    padding: 0 1.5rem; }
    .trumbowyg-dropdown button svg {
      margin-top: -3px; }
    .trumbowyg-dropdown button:hover {
      background-color: #f9f9f9; }

.trumbowyg-modal-box {
  font-size: 1rem;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11); }
  .trumbowyg-modal-box .trumbowyg-modal-title {
    font-size: 1.2rem;
    color: #333;
    background-color: #FFFFFF;
    font-weight: 500;
    border: 0; }
  .trumbowyg-modal-box label {
    margin: 15px 20px;
    font-weight: normal; }
    .trumbowyg-modal-box label .trumbowyg-input-infos span {
      color: #333;
      border-color: #eceff1; }
    .trumbowyg-modal-box label input {
      border-color: #eceff1;
      font-size: 1rem;
      color: #495057; }
      .trumbowyg-modal-box label input:focus, .trumbowyg-modal-box label input:hover {
        border-color: #dde2e6; }
  .trumbowyg-modal-box .trumbowyg-modal-button {
    font-size: 1rem;
    height: auto;
    line-height: 100%;
    border-radius: 2px;
    padding: 7px 0;
    margin: 0 20px;
    bottom: 18px; }

.fc-scroller {
  height: auto !important; }

.fc th {
  font-weight: 500;
  padding: 12px 12px 10px;
  text-transform: uppercase; }

.fc table {
  background: transparent; }
  .fc table tr > td:first-child {
    border-left-width: 0; }

.fc div.fc-row {
  margin-right: 0;
  border: 0; }

.fc button .fc-icon {
  top: -5px; }

.fc-unthemed td.fc-today {
  background-color: transparent; }
  .fc-unthemed td.fc-today span {
    color: #FF9800; }

.fc-event {
  padding: 0;
  font-size: 0.92rem;
  border-radius: 2px;
  border: 0; }
  .fc-event .fc-title {
    padding: 4px 8px;
    display: block;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500; }
  .fc-event .fc-time {
    float: left;
    background: rgba(0, 0, 0, 0.2);
    padding: 2px 6px;
    margin: 0 0 0 -1px; }

.fc-view,
.fc-view > table {
  border: 0;
  overflow: hidden; }

.fc-view > table > tbody > tr .ui-widget-content {
  border-top: 0; }

.fc-icon {
  font-family: "Material-Design-Iconic-Font";
  font-size: 1.5rem;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  transition: background-color 300ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fc-icon {
      transition: none; } }
  .fc-icon:hover {
    background-color: #f6f6f6; }

.fc-button {
  border: 0;
  background: transparent;
  box-shadow: none; }

.calendar {
  z-index: 0; }
  .calendar td, .calendar th {
    border-color: #f8f9fa; }
  .calendar .fc-toolbar {
    height: 250px;
    background-color: #FFFFFF;
    border-radius: 2px 2px 0 0;
    position: relative;
    margin-bottom: -2px;
    z-index: 2; }
    @media (max-width: 575.98px) {
      .calendar .fc-toolbar {
        height: 135px; } }
  .calendar .fc-day-number {
    padding: 6px 10px;
    width: 100%;
    box-sizing: border-box; }
    @media (min-width: 576px) {
      .calendar .fc-day-number {
        font-size: 1.5rem;
        color: #8e9499; } }
  .calendar .fc-day-header {
    text-align: left; }
  .calendar .fc-day-grid-event {
    margin: 1px 9px; }

.widget-calendar td, .widget-calendar th {
  border-color: transparent;
  text-align: center; }

.widget-calendar .fc-toolbar h2 {
  font-size: 1.2rem;
  padding-top: 0.3rem; }

.widget-calendar .fc-day-number {
  text-align: center;
  width: 100%;
  padding: 0; }

.widget-calendar__header {
  background-color: #d066e2;
  border-radius: 2px 2px 0 0;
  padding: 2.2rem 2.1rem; }
  .widget-calendar__header .actions {
    position: absolute;
    top: 2.5rem;
    right: 1.5rem; }

.widget-calendar__year {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.5rem;
  line-height: 100%; }

.widget-calendar__day {
  font-size: 1.5rem;
  line-height: 100%;
  color: #FFFFFF; }

.widget-calendar__body {
  padding: 1rem;
  margin-top: 1rem; }

.event-tag {
  margin-bottom: 1.5rem; }
  .event-tag > span {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0 0 3px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    cursor: pointer; }
    .event-tag > span,
    .event-tag > span > i {
      transition: all 200ms; }
      @media screen and (prefers-reduced-motion: reduce) {
        .event-tag > span,
        .event-tag > span > i {
          transition: none; } }
    .event-tag > span > input[type=radio] {
      margin: 0;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
      cursor: pointer;
      opacity: 0; }
      .event-tag > span > input[type=radio]:checked + i {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1); }
    .event-tag > span:hover {
      opacity: 0.8; }
    .event-tag > span > i {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 4px 0 0 7px;
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0); }
      .event-tag > span > i:before {
        content: '\f26b';
        font-family: "Material-Design-Iconic-Font";
        color: #FFFFFF;
        font-size: 1.2rem;
        z-index: 1; }

[data-calendar-month] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: background-image 300ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    [data-calendar-month] {
      transition: none; } }

[data-calendar-month="0"] {
  background-image: url("../img/calendar/january.jpg"); }

[data-calendar-month="1"] {
  background-image: url("../img/calendar/february.jpg"); }

[data-calendar-month="2"] {
  background-image: url("../img/calendar/march.jpg"); }

[data-calendar-month="3"] {
  background-image: url("../img/calendar/april.jpg"); }

[data-calendar-month="4"] {
  background-image: url("../img/calendar/may.jpg"); }

[data-calendar-month="5"] {
  background-image: url("../img/calendar/june.jpg"); }

[data-calendar-month="6"] {
  background-image: url("../img/calendar/july.jpg"); }

[data-calendar-month="7"] {
  background-image: url("../img/calendar/august.jpg"); }

[data-calendar-month="8"] {
  background-image: url("../img/calendar/september.jpg"); }

[data-calendar-month="9"] {
  background-image: url("../img/calendar/october.jpg"); }

[data-calendar-month="10"] {
  background-image: url("../img/calendar/november.jpg"); }

[data-calendar-month="11"] {
  background-image: url("../img/calendar/december.jpg"); }

.swal2-modal {
  border-radius: 2px;
  padding: 2.5rem !important;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11); }
  .swal2-modal .swal2-title {
    font-size: 1.1rem;
    position: relative;
    z-index: 1;
    color: #333;
    line-height: inherit;
    margin: 0 0 5px;
    font-weight: normal; }
  .swal2-modal .swal2-icon,
  .swal2-modal .swal2-image {
    margin-top: 0;
    margin-bottom: 1.5rem; }
  .swal2-modal .swal2-content {
    color: #9c9c9c;
    font-size: 1rem;
    font-weight: normal; }
  .swal2-modal .swal2-actions {
    margin-top: 30px; }
    .swal2-modal .swal2-actions .btn {
      margin: 0 3px;
      box-shadow: none !important; }

.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, 0.2); }

.lg-outer .lg-thumb-item {
  border: 0; }
  .lg-outer .lg-thumb-item:not(.active) {
    opacity: 0.25; }
    .lg-outer .lg-thumb-item:not(.active):hover {
      opacity: 0.7; }

.lg-outer .lg-image {
  border-radius: 2px; }

.lg-outer .lg-toogle-thumb {
  border-radius: 50%;
  color: #333;
  height: 51px;
  width: 51px;
  line-height: 41px;
  background-color: #FFFFFF;
  transition: all 500ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    .lg-outer .lg-toogle-thumb {
      transition: none; } }
  .lg-outer .lg-toogle-thumb:hover {
    color: #333; }

.lg-outer:not(.lg-thumb-open) .lg-toogle-thumb {
  top: -70px; }

.lg-outer.lg-thumb-open .lg-toogle-thumb {
  top: -26px; }

.lg-thumb.group {
  padding: 20px 0; }

.lg-slide em {
  font-style: normal; }
  .lg-slide em h3 {
    color: #FFFFFF;
    margin-bottom: 5px; }

.lg-slide .video-cont {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12); }

.lightbox > a {
  position: relative; }
  .lightbox > a:before, .lightbox > a:after {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 300ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .lightbox > a:before, .lightbox > a:after {
        transition: none; } }
  .lightbox > a:before {
    content: '\f1ee';
    font-family: "Material-Design-Iconic-Font";
    font-size: 2.3rem;
    color: #FFFFFF;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    line-height: 25px;
    z-index: 2;
    -webkit-transform: scale(2);
            transform: scale(2); }
  .lightbox > a:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1; }
  .lightbox > a:hover:before, .lightbox > a:hover:after {
    opacity: 1; }
  .lightbox > a:hover:before {
    -webkit-transform: scale(1);
            transform: scale(1); }

.scrollbar-inner {
  height: 100%;
  overflow: auto; }
  .scrollbar-inner > .scroll-element {
    transition: opacity 300ms;
    margin-right: 2px; }
    @media screen and (prefers-reduced-motion: reduce) {
      .scrollbar-inner > .scroll-element {
        transition: none; } }
    .scrollbar-inner > .scroll-element.scroll-y {
      width: 3px;
      right: 0; }
    .scrollbar-inner > .scroll-element.scroll-x {
      height: 3px;
      bottom: 0; }
    .scrollbar-inner > .scroll-element .scroll-bar,
    .scrollbar-inner > .scroll-element .scroll-element_track {
      transition: background-color 300ms; }
      @media screen and (prefers-reduced-motion: reduce) {
        .scrollbar-inner > .scroll-element .scroll-bar,
        .scrollbar-inner > .scroll-element .scroll-element_track {
          transition: none; } }
    .scrollbar-inner > .scroll-element .scroll-element_track {
      background-color: transparent; }
  .scrollbar-inner:not(:hover) .scroll-element {
    opacity: 0; }

.waves-effect {
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent; }
  .waves-effect .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    opacity: 0;
    transition: all 0.5s ease-out;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transform: scale(0) translate(0, 0);
            transform: scale(0) translate(0, 0);
    pointer-events: none; }
  .waves-effect:not(.waves-light) .waves-ripple, .waves-effect.btn-secondary .waves-ripple, .waves-effect.btn-link .waves-ripple {
    background: rgba(0, 0, 0, 0.08); }
  .waves-effect.btn:not(.btn-secondary):not(.btn-link) .waves-ripple, .waves-effect.waves-light .waves-ripple {
    background: rgba(255, 255, 255, 0.5); }
  .waves-effect.waves-classic .waves-ripple {
    background: rgba(0, 0, 0, 0.08); }
  .waves-effect.waves-classic.waves-light .waves-ripple {
    background: rgba(255, 255, 255, 0.5); }

.waves-notransition {
  transition: none !important; }

.waves-button,
.waves-circle {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, #FFFFFF 100%, #000000 100%); }

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  border: 0; }

.waves-block {
  display: block; }

ul.jqtree-tree {
  border-top: 1px solid #f1f4f7; }
  ul.jqtree-tree li.jqtree-selected > .jqtree-element, ul.jqtree-tree li.jqtree-selected > .jqtree-element:hover {
    background: #f9f9f9;
    text-shadow: none; }
  ul.jqtree-tree li:not(.jqtree-selected) > .jqtree-element:hover {
    background: #fcfcfc; }
  ul.jqtree-tree li.jqtree-folder {
    margin-bottom: 0; }
    ul.jqtree-tree li.jqtree-folder:not(.jqtree-closed) + li.jqtree_common {
      position: relative; }
      ul.jqtree-tree li.jqtree-folder:not(.jqtree-closed) + li.jqtree_common:before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        background-color: #f1f4f7;
        height: 1px; }
    ul.jqtree-tree li.jqtree-folder.jqtree-closed {
      margin: 0; }
  ul.jqtree-tree li.jqtree-ghost span.jqtree-line {
    background-color: #2196F3; }
  ul.jqtree-tree li.jqtree-ghost span.jqtree-circle {
    border-color: #2196F3; }
  ul.jqtree-tree .jqtree-moving > .jqtree-element .jqtree-title {
    outline: none; }
  ul.jqtree-tree span.jqtree-border {
    border-radius: 0;
    border-color: #2196F3; }
  ul.jqtree-tree .jqtree-toggler {
    position: absolute;
    height: 18px;
    width: 18px;
    background: #FFFFFF;
    border: 1px solid #e1e7ee;
    color: #333;
    border-radius: 50%;
    padding: 0 0 0 1px;
    top: 12px;
    left: -10px;
    line-height: 17px;
    font-size: 1rem;
    text-align: center; }
  ul.jqtree-tree .jqtree-element {
    position: relative;
    padding: 10px 20px;
    border: 1px solid #f1f4f7;
    border-top: 0;
    margin-bottom: 0; }
  ul.jqtree-tree .jqtree-title {
    color: #333;
    margin-left: 0; }
  ul.jqtree-tree ul.jqtree_common {
    margin-left: 22px;
    padding-left: 10px; }

.jq-ry-container {
  padding: 0;
  display: inline-block; }

.text-count-wrapper {
  position: absolute;
  bottom: -23px;
  height: 20px;
  width: 100%;
  left: 0;
  font-size: 0.875rem; }

.error-text-min {
  float: right; }
/*# sourceMappingURL=app.css.map */