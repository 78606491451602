.header {
    background-color: #213896 !important;
}

.invalid-feedback {
    display: block;
}

.invalid-tooltip {
    display: block;
}